import React from "react";
import { Form, Input, Radio, Button, message, Switch, Select, InputNumber } from "antd";
import api from "api";
import { createSearchText } from "common/Text";
import { useNavigate, useParams } from "react-router-dom";
import UploadImagePicker from "./images_picker";
import PopupOrderInfo from "./popup_order_info";
import { formatCurrency } from "common/Format2";

const { TextArea } = Input;

const SaveOrderOnline = (props) => {
  const [loadingWorker, setLoadingWorker] = React.useState(true);
  const [lstWorker, setLstWorker] = React.useState([]);
  const form = React.useRef();
  const popupOrderInfo = React.useRef();
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(() => {
    getListWorker();
  }, []);

  React.useEffect(() => {
    if (form.current != null) {
      if (id) {
        api.get(`/OrderOnline/GetByID?id=${id}`).then((res) => {
          res.data.product_images = res.data.images ? JSON.parse(res.data.images) : [];
          form.current.setFieldsValue(res.data);
        });
      } else form.current.setFieldsValue({ is_delivery: false, source: "zalo", deposit: 0 });
    }
  }, [form, id]);

  const onFinish = (values) => {
    if (values.product_images == null) values.product_images = [];
    api.post("OrderOnline/SaveOrder", values).then((res) => {
      message.success("Lưu thành công");
      if (!id) {
        form.current.resetFields();
        form.current.setFieldsValue({ is_delivery: false, source: "zalo", deposit: 0 });
      }
      popupOrderInfo.current.open(res.id);
    });
  };

  const getListWorker = () => {
    api.get("Worker/GetList").then((res) => {
      setLstWorker(res.data);
      setLoadingWorker(false);
    });
  };

  return (
    <div>
      <Form ref={form} onFinish={onFinish} layout="vertical" defaultValue={{ is_delivery: false }}>
        <Form.Item name="id" hidden></Form.Item>
        <Form.Item label="Nguồn đơn" rules={[{ required: true }]} name={"source"}>
          <Radio.Group>
            <Radio value="zalo"> Zalo </Radio>
            <Radio value="facebook"> Facebook </Radio>
            <Radio value="website"> Website </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Tên KH" rules={[{ required: true }]} name="customer_name">
          <Input />
        </Form.Item>
        <Form.Item label="SDT" name="customer_phone">
          <Input />
        </Form.Item>
        <Form.Item label="Địa chỉ" name="customer_address">
          <Input />
        </Form.Item>
        <Form.Item label="Thợ" name="worker_id">
          <Select
            loading={loadingWorker}
            size="large"
            style={{
              width: "100%",
            }}
            fieldNames={{ value: "id", label: "name" }}
            options={lstWorker}
            showSearch={true}
            autoClearSearchValue={true}
            filterOption={(key, option) => {
              key = createSearchText(key);
              var label = createSearchText(option.name);
              if (label.includes(key)) return true;
              return false;
            }}
            virtual={false}
          />
        </Form.Item>
        <div style={{ display: "flex", gap: 40 }}>
          <Form.Item label="Giá" name="price">
            <InputNumber
              style={{ width: 150 }}
              inputMode="numeric"
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              step={1000}
              rules={[{ required: true }]}
              size="large"
            ></InputNumber>
          </Form.Item>
          <Form.Item label="Cọc" name="deposit">
            <InputNumber
              style={{ width: 150 }}
              inputMode="numeric"
              min={0}
              rules={[{ required: true }]}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              step={1000}
              size="large"
            ></InputNumber>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.price !== currentValues.price || prevValues.deposit !== currentValues.deposit}
          >
            {({ getFieldValue }) => {
              var price = getFieldValue("price");
              var deposit = getFieldValue("deposit");
              var remain = price ?? 0;
              if (price && deposit) remain = price - deposit;
              return (
                <div style={{ display: "flex", height: 70 }}>
                  <div style={{ marginTop: "auto", marginBottom: "auto" }}>{`Còn lại: ${formatCurrency(remain)}`}</div>
                </div>
              );
            }}
          </Form.Item>
        </div>
        <Form.Item label="Ghi chú" name="notes">
          <TextArea />
        </Form.Item>

        <Form.Item label="Giao hàng" name="is_delivery">
          <Switch />
        </Form.Item>
        <Form.Item label="Hình ảnh sản phẩm" name="product_images">
          <UploadImagePicker />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button size="large" type="primary" htmlType="submit">
            Save
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              form.current.resetFields();
            }}
          >
            Hủy
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              navigate("/order_online");
            }}
          >
            Trở về trang danh sách
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              navigate("/");
            }}
          >
            Trở về trang chủ
          </Button>
        </div>
      </Form>
      <PopupOrderInfo ref={popupOrderInfo} />
    </div>
  );
};
export default SaveOrderOnline;
