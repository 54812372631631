import React from "react";
import api from "api";
import { Input, Button, Radio, Card, Pagination } from "antd";
import PopupOrderInfo from "./popup_order_info";
import { formatLongDate } from "common/Format2";
import { useNavigate } from "react-router-dom";

const ListOrderLive = () => {
  const [lstData, setLstData] = React.useState([]);
  const [info, setInfo] = React.useState();
  const [status, setStatus] = React.useState();
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(1);
  const popupOrderInfo = React.useRef();
  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, [page, info, status]);

  const getData = () => {
    api.post("OrderOnline/GetList", { StartRow: (page - 1) * 20, Info: info, Status: status }).then((res) => {
      setLstData(res.data.data);
      setTotal(res.data.total);
    });
  };

  const pageChange = (e) => {
    setPage(e);
  };

  return (
    <div>
      <Card>
        <Input
          onChange={(e, f) => {
            setInfo(e.target.value);
          }}
          value={info}
        />
        <Radio.Group
          style={{ marginTop: 2 }}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
          value={status}
        >
          <Radio value={1}> Đã giao </Radio>
          <Radio value={2}> Chưa giao </Radio>
          <Radio value={3}> Tất cả </Radio>
        </Radio.Group>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="large"
            onClick={() => {
              navigate("/save_order_online");
            }}
            type="primary"
          >
            Thêm mới
          </Button>
        </div>
      </Card>
      <Card style={{ marginTop: 20 }}>
        <div style={{ fontWeight: "bold" }}>
          {lstData.map((x, index) => {
            return (
              <div style={{ marginBottom: 20 }}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    popupOrderInfo.current.open(x.id);
                  }}
                >{`${index + 1}.  ${x.customer_name}`}</div>
                <div style={{ marginLeft: 50 }}>{`Đặt hàng vào lúc: ${formatLongDate(x.create_date)}`}</div>
              </div>
            );
          })}
        </div>
        <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 20 }}>
          <Pagination current={page} total={total} onChange={pageChange} pageSize={20} showSizeChanger={false}></Pagination>
        </div>
      </Card>
      <PopupOrderInfo ref={popupOrderInfo} />
    </div>
  );
};
export default ListOrderLive;
