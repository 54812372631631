import { Table, Card, Select, Button, Space, Popconfirm, message, Input, Checkbox, Tag, Radio } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  TruckOutlined,
  PrinterOutlined,
  ApiOutlined,
  FileProtectOutlined,
  SearchOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import React from "react";
import dayjs from "dayjs";
import api from "api";
import { Routes, Route, useParams } from "react-router-dom";
import { formatLongDate, formatCurrency } from "common/Format2";
import { useNavigate } from "react-router-dom";
import OrderDetail from "./order_detail";
import CustomerLivePopup from "./customer_live_popup";
import JATConfigPopup from "./jat_config_popup";
import JATCreateOrderPopup from "./jat_create_order_popup";
import ChangeSession from "./popup_change_live_session";
import { Print } from "component/print";
import JATCancelOrderPopup from "./cancel_order_jat_popup";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const Orders = () => {
  const { session_id } = useParams();
  const [lstSession, setLstSession] = React.useState([]);
  const [value, setValue] = React.useState(session_id);
  const [info, setInfo] = React.useState("");
  const [orderType, setOrderType] = React.useState(3);
  const [data, setData] = React.useState([]);
  const [recordPrint, setRecordPrint] = React.useState({});
  const [typePrint, setTypePrint] = React.useState([]);
  const [cusPrint, setCusPrint] = React.useState({});
  const [shopConfig, setShopConfig] = React.useState({});
  const [addressShop, setAddressShop] = React.useState({});
  const [lstDetail, setLstDetail] = React.useState([]);
  const [addresCus, setAddressCus] = React.useState({});
  const [summary, setSummary] = React.useState({ total_od: 0, total_money: 0 });
  const detailRef = React.useRef(null);
  const customerLivePopup = React.useRef();
  const changeSession = React.useRef();
  const jatConfigPopup = React.useRef();
  const jatCreateOrderPopup = React.useRef();
  const jatCancelOrderPopup = React.useRef();
  const printRef = React.useRef();
  const navigate = useNavigate();

  React.useEffect(() => {
    api.get("ShopConfig/GetConfig").then((res) => {
      if (res.data) {
        api.get("Address/GetAddressByCode?code=" + res.data.shop_address_code).then((res2) => {
          setAddressShop(res2.data);
        });
        setShopConfig(res.data);
      }
    });
    api.get("LiveOrder/GetListSession").then((res) => {
      var data = res.lst.map((item) => {
        return {
          value: item.id,
          label:
            item.live_content && item.live_content != ""
              ? `${item.live_content}`
              : `Phiên live ${formatLongDate(item.start_time)} - ${item.end_time ? formatLongDate(item.end_time) : "Chưa kết thúc"}`,
          start_time: dayjs(item.start_time),
        };
      });
      setLstSession(data);
    });

    if (value) {
      getData();
    }
  }, []);

  React.useEffect(() => {
    getData();
  }, [value]);
  const getData = () => {
    api.get(`LiveOrder/GetOrders?session_id=${value}&info=${info}&orderType=${orderType}`).then((res) => {
      setData(res.lst);
      setSummary({
        total_od: res.lst.length,
        total_products: res.lst.reduce((acc, cur) => (acc += cur.products.split(",").length), 0),
        total_money: res.lst.reduce((acc, cur) => (acc += cur.total_price), 0),
      });
    });
  };

  const confirm = (id) => {
    api.get("LiveOrder/Delete?id=" + id).then((res) => {
      message.success("Xóa thành công!");
      getData();
    });
  };

  const columns = [
    {
      title: "Tên khách",
      dataIndex: "customer_name",
      key: "customer_name",
      render: (text, record, index) => {
        if (record.customer_id) {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (record.customer_id) customerLivePopup.current.open(record.customer_id, record.id);
                else message.error("Dữ liệu đã cũ");
              }}
            >
              <div
                style={{
                  marginBottom: 5,
                }}
              >
                <span
                  style={{
                    background: record.is_goods_bomb ? "#f50505" : record.is_warning ? "#f58d05" : "transparent",
                    padding: "0px 5px 0px 5px",
                    borderRadius: 4,
                  }}
                >
                  {record.customer_name2}
                </span>
              </div>
              {record.is_wholesale ? (
                <div style={{ marginBottom: 5 }}>
                  <Tag color="green">Khách sỉ</Tag>
                </div>
              ) : (
                <div style={{ marginBottom: 5 }}>
                  <Tag color="blue">Khách lẻ</Tag>
                </div>
              )}
              <div style={{ display: "flex" }}>
                {record.customer_phone ? <img src="/image/phone2.png" style={{ width: 20, height: 20, marginRight: 10 }} /> : <></>}
                {record.customer_address && record.customer_address_code ? (
                  <img src="/image/address2.jfif" style={{ width: 20, height: 20, marginRight: 10 }} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div style={{ marginBottom: 5 }}>
              <div>{text}</div>
              {record.is_wholesale ? (
                <div>
                  <Tag color="green">Khách sỉ</Tag>
                </div>
              ) : (
                <div>
                  <Tag color="blue">Khách lẻ</Tag>
                </div>
              )}
            </div>
          );
        }
      },
    },
    {
      title: "Sản phẩm",
      dataIndex: "products",
      key: "products",
      render: function (text, record, index) {
        return (
          <div>
            {text.split(",").map((item) => (
              <p>{item}</p>
            ))}
          </div>
        );
      },
    },
    {
      title: "SL",
      dataIndex: "products",
      key: "products",
      render: function (text, record, index) {
        var products = record.products.split(", ");
        if (products.length == 1) products = record.products.split(",");
        var count = 0;
        products.forEach((x) => {
          var lstSplit = x.split(" ");
          count += lstSplit[0] * 1;
        });
        return <div>{count}</div>;
      },
    },
    {
      title: "Giá trị",
      dataIndex: "total_price",
      key: "total_price",
      render: function (text, record, index) {
        return formatCurrency(text);
      },
    },

    {
      title: "Freeship",
      dataIndex: "is_freeship",
      key: "is_freeship",
      render: function (text, record, index) {
        return (
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Checkbox
              checked={record.is_freeship}
              onChange={(e) => {
                api
                  .get(`LiveOrder/ChangeFreeShip?id=${record.id}&is_freeship=${e.target.checked}`)
                  .then((res) => {
                    message.success("Lưu thành công");
                    getData();
                  })
                  .catch((err) => {
                    message.error("Lưu thất bại");
                  });
              }}
            ></Checkbox>
          </div>
        );
      },
    },
    {
      title: "Mã vận đơn",
      dataIndex: "transpost_id",
      key: "transpost_id",
    },
    {
      title: "Trạng thái J&T",
      dataIndex: "transpost_status",
      key: "transpost_status",
      render: function (text, record, index) {
        if (record.transpost_status == "created") {
          return <Tag color="green">Đã gửi đơn</Tag>;
        }
        if (record.transpost_status == "cancelled") {
          return <Tag color="orange">Đã hủy đơn</Tag>;
        }
      },
    },
    {
      title: "Ghi chú",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Ngày tạo",
      dataIndex: "create_time",
      key: "create_time",
      align: "center",
      render: function (text, record, index) {
        return (
          <div>
            <p>{record.name}</p>
            <p>{formatLongDate(text)}</p>
          </div>
        );
      },
    },
    {
      title: (
        <Button
          type="primary"
          onClick={() => {
            detailRef.current.open();
          }}
          disabled={value == null}
        >
          Thêm mới
        </Button>
      ),
      key: "action",
      align: "center",
      render: function (text, record, index) {
        return (
          <div>
            <div style={{ marginBottom: 10 }}>
              <Space>
                <Button
                  type={"primary"}
                  onClick={() => {
                    detailRef.current.open(record.id);
                  }}
                  icon={<EditOutlined />}
                />
                <Popconfirm
                  title="Xóa đơn hàng"
                  description="Bạn có muốn xóa đơn hàng này?"
                  onConfirm={() => confirm(record.id)}
                  okText="Đồng ý"
                  cancelText="Không"
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
                <Button
                  type={"primary"}
                  onClick={async () => {
                    var data = await api.get("LiveOrder/GetListOrderDetail?id=" + record.id);
                    if (data.code == 1) setLstDetail(data.data);
                    setRecordPrint(record);
                    setTypePrint("bill_live_order");
                    setTimeout(() => {
                      printRef.current.print();
                    }, 500);
                  }}
                  icon={<PrinterOutlined />}
                ></Button>
              </Space>
            </div>
            <div>
              <Space>
                <Button
                  type={"primary"}
                  onClick={() => {
                    changeSession.current.open(record.id);
                  }}
                  icon={<SwapOutlined />}
                ></Button>
                {record.customer_phone && record.customer_address && record.customer_address_code && record.transpost_status != "created" ? (
                  <Button
                    type={"primary"}
                    onClick={() => {
                      jatCreateOrderPopup.current.open(record.id);
                    }}
                    icon={<TruckOutlined />}
                  />
                ) : (
                  <></>
                )}
                {record.transpost_status == "created" ? (
                  <>
                    <Button
                      icon={<ApiOutlined />}
                      type={"primary"}
                      onClick={() => {
                        jatCancelOrderPopup.current.open(record.id);
                      }}
                    ></Button>
                    <Button
                      icon={<FileProtectOutlined />}
                      type={"primary"}
                      onClick={() => {
                        if (!record.customer_id) {
                          message.error("Dữ liệu đã cũ.");
                          return;
                        }
                        printBillJAT(record);
                      }}
                    ></Button>
                  </>
                ) : (
                  <></>
                )}
              </Space>
            </div>
          </div>
        );
      },
    },
  ];

  const printBillJAT = (record) => {
    api.get("CustomerLive/GetByID?id=" + record.customer_id).then((res) => {
      record.jat_respone = JSON.parse(record.jat_create_order_respone);

      api.get("Address/GetAddressByCode?code=" + res.data.address_code).then((res2) => {
        setAddressCus(res2.data);
        setCusPrint(res.data);
        setRecordPrint(record);
        setTypePrint("bill_jat_order");
        setTimeout(() => {
          printRef.current.print();
        }, 1000);
      });
    });
  };

  return (
    <div>
      <Card style={{ marginBottom: 20 }}>
        <div style={{ marginBottom: 20, display: "flex", gap: 20 }}>
          <Select
            style={{ flex: 1 }}
            placeholder={"chọn phiên live"}
            options={lstSession}
            value={value}
            onChange={(value) => {
              setValue(value);
            }}
          />

          <Input
            placeholder="Tên, SDT khách hàng"
            style={{ flex: 1 }}
            value={info}
            onChange={(e) => {
              setInfo(e.target.value);
            }}
          ></Input>
          <Radio.Group
            onChange={(e) => {
              setOrderType(e.target.value);
            }}
            value={orderType}
            style={{ flex: 1 }}
          >
            <Radio value={1}>Chưa gửi</Radio>
            <Radio value={2}>Đã gửi</Radio>
            <Radio value={3}>Tất cả</Radio>
          </Radio.Group>
          <div>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {
                getData();
              }}
            >
              Tìm
            </Button>
          </div>
        </div>
        <div style={{ marginTop: 15, display: "flex", flex: 1, flexDirection: "row" }}>
          <div style={{ flexGrow: 1 }}>
            <label>
              <b>Tổng đơn: {summary.total_od}</b>
            </label>
          </div>
          <div style={{ flexGrow: 1 }}>
            <label>
              <b>Tổng sản phẩm: {summary.total_products}</b>
            </label>
          </div>
          <div style={{ flexGrow: 1 }}>
            <label>
              <b>Tổng tiền: {formatCurrency(summary.total_money)}</b>
            </label>
          </div>
        </div>
      </Card>
      <Card style={{ marginBottom: 20 }}>
        <Table
          dataSource={data}
          columns={columns}
          pagination={{
            position: ["topRight", "bottomRight"],
          }}
        />
      </Card>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{ marginRight: 50 }}
          size="large"
          type="primary"
          onClick={() => {
            navigate("/");
          }}
        >
          Trở lại
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            jatConfigPopup.current.open();
          }}
        >
          Cấu hình J&t
        </Button>
      </div>
      <OrderDetail ref={detailRef} getData={() => getData()} sessionID={value} />
      <CustomerLivePopup ref={customerLivePopup} reload={getData}></CustomerLivePopup>
      <JATConfigPopup ref={jatConfigPopup}></JATConfigPopup>
      <JATCreateOrderPopup ref={jatCreateOrderPopup} reload={getData} printBillJAT={printBillJAT}></JATCreateOrderPopup>
      <JATCancelOrderPopup ref={jatCancelOrderPopup} reload={getData}></JATCancelOrderPopup>
      <ChangeSession ref={changeSession} reload={getData} />
      <div style={{ display: "none" }}>
        <Print
          type={typePrint}
          data={recordPrint}
          cus={cusPrint}
          config={shopConfig}
          shopAddres={addressShop}
          customerAddres={addresCus}
          ref={printRef}
          details={lstDetail}
        />
      </div>
    </div>
  );
};
export default Orders;
