import React from "react";
import { formatCurrency, formatShortDate } from "common/Format2";
import { Row, Col } from "antd";
import { Header } from "Client";
import dayjs from "dayjs";

const BillLiveOrder = React.forwardRef((props, ref) => {
  const { data, details } = props;

  const renderCode = () => {
    if (data.products) {
      var lstSplit = data.products.split(", ");
      if (lstSplit.length == 1) lstSplit = data.products.split(",");

      return (
        <>
          {details.map((x) => {
            return (
              <>
                <Col span={7} style={{ fontWeight: "bold", fontSize: 10 }}>
                  {x.product}
                </Col>
                <Col span={13} style={{ fontWeight: "bold", fontSize: 10 }}>
                  {formatCurrency(x.price)}
                </Col>
                <Col span={4} style={{ fontWeight: "bold", fontSize: 10 }}>
                  {x.quantity}
                </Col>
              </>
            );
          })}
        </>
      );
    }
  };

  const getCount = () => {
    var count = 0;
    if (data.products) {
      var products = data.products.split(", ");
      if (products.length == 1) products = data.products.split(",");
      products.forEach((x) => {
        var lstSplit = x.split(" ");
        count += lstSplit[0] * 1;
      });
    }
    return count;
  };
  return (
    <div className="bodyPrint" style={{ fontSize: 10 }} ref={ref}>
      <style>{`@media print {body{zoom: 94%;}}`}</style>
      <div style={{ marginTop: 20 }}>
        <div style={{ marginLeft: 40, marginRight: 20 }}>
          <div style={{ fontWeight: "bold", fontSize: 15 }}>Áo Dài Trang Võ</div>
          <div>44 Dương Tử Giang, p14, Q5, TPHCM (chợ sỉ áo dài Soái Kình Lâm)</div>
          <div style={{ fontWeight: "bold" }}>SDT:0813.115.388</div>
        </div>
        <hr style={{ width: "100%", borderWidth: 1, borderColor: "black" }} />
        <div style={{ lineHeight: 1.5, marginLeft: 10, marginRight: 10 }}>
          <div style={{ textAlign: "center", fontSize: 15, fontWeight: "bold" }}>{`Toa chốt hàng phiên live ${formatShortDate(data.live_start_time)}`}</div>
          <Row style={{ marginBottom: 20 }}>
            <Col span={6}>Tên:</Col>
            <Col span={18}>
              <div style={{ fontSize: 15, fontWeight: "bold" }}>{data.customer_name2 ? data.customer_name2 : data.customer_name}</div>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col span={7} style={{ fontSize: 8 }}>
              Mã số:
            </Col>
            <Col span={13}>
              <div style={{ marginLeft: 20, fontSize: 8 }}>Giá:</div>
            </Col>
            <Col span={4} style={{ fontSize: 8 }}>
              SL:
            </Col>
            {renderCode()}
          </Row>
        </div>
        <hr style={{ width: "100%", borderWidth: 1, borderColor: "black" }} />
        <div style={{ fontWeight: "bold", textAlign: "right" }}>{`Tổng: ${formatCurrency(data.total_price)} | SL:${getCount()} `}</div>
      </div>
      {/* <div className="pagebreak" /> */}
    </div>
  );
});
export default BillLiveOrder;
