const api_url = window.config.api_url;
const imageURL = window.config.imageURL;
const uploadImageURL = window.config.uploadImageURL;
const myDomain = window.config.myDomain;
const serverDomain = window.config.serverDomain;
const realtimeUrl = window.config.realtimeUrl;


export {
  api_url,
  imageURL,
  uploadImageURL,
  myDomain,
  serverDomain,
  realtimeUrl
};
