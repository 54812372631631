import React from "react";
import { Row, Col, DatePicker, Button, Card } from "antd";
import { Header } from "Client";
import { animateScroll as scroll } from "react-scroll";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import api from "api";

const WorkerFabricHistory = () => {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [listData, setListData] = React.useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { header } = React.useContext(Header);

  React.useEffect(() => {
    getLastTransaction();
  }, []);

  const getLastTransaction = () => {
    api.get("Worker/GetLastTransaction?workerID=" + id).then((res) => {
      setStartDate(dayjs(res.data.create_date));
      setEndDate(dayjs(res.data.create_date));
    });
  };
  React.useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const getData = () => {
    var lstDay = [startDate];
    var start = dayjs(startDate);
    while (start < endDate) {
      start = start.add(1, "day");
      lstDay.push(start);
    }
    api.post("Worker/GetListTransactionFabric", { WorkerID: id, LstDate: lstDay }).then((res) => {
      setListData(res.data);
    });
  };
  return (
    <div>
      <div>
        <div>
          <div style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
            <div style={{ flex: 1 }}>
              <DatePicker format={"DD/MM/YYYY"} size="large" style={{ width: "100%" }} onChange={setStartDate} value={startDate} />
            </div>
            <div style={{ display: "flex", margin: 5 }}>
              <div style={{ margin: "auto" }}>Đến</div>
            </div>
            <div style={{ flex: 1 }}>
              <DatePicker format={"DD/MM/YYYY"} size="large" style={{ width: "100%" }} onChange={setEndDate} value={endDate} />
            </div>
          </div>
        </div>
      </div>
      <div>
        {listData.map((x) => {
          return (
            <>
              {x.lstFabric && x.lstFabric.length > 0 ? (
                <div style={{ marginBottom: 20 }}>
                  <div style={{ marginBottom: 20 }}>
                    <div style={{ fontWeight: "bold", marginBottom: 10, color: "red", fontSize: 20 }}>{dayjs(x.Date).format("DD-MM-YYYY")}</div>
                  </div>
                  <Card>
                    <Row gutter={20}>
                      {x.lstFabric.map((y) => {
                        return <Col span={8}>{`${y.fabric_name}: (${y.remaining_fabric})`}</Col>;
                      })}
                    </Row>
                  </Card>
                </div>
              ) : (
                <></>
              )}
            </>
          );
        })}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", position: "fixed", bottom: 20, left: 20, right: 20, zIndex: 1000 }}>
        <Button
          type="primary"
          size="large"
          style={{ width: "32%" }}
          onClick={() => {
            scroll.scrollToBottom();
          }}
        >
          End
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            navigate("/");
          }}
          style={{ width: "32%" }}
        >
          Home
        </Button>
        <Button
          type="primary"
          size="large"
          style={{ width: "32%" }}
          onClick={() => {
            navigate("/worker/" + id);
          }}
        >
          Trở lại
        </Button>
      </div>
    </div>
  );
};
export default WorkerFabricHistory;
