import React from "react";
import { Button, Table, Card, DatePicker, Tag, Row, Col } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import api from "api";
import dayjs from "dayjs";
import { formatCurrency, formatShortDate } from "common/Format2";
import WorkerHistoryPrint from "component/print/worker_history_print";
import { Print } from "component/print";
import { animateScroll as scroll } from "react-scroll";
import { Header } from "Client";

const WorkerHistory = (props) => {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [listData, setListData] = React.useState([]);
  const [listDataFabric, setListDataFabric] = React.useState([]);
  const [mode, setMode] = React.useState("web");
  const { id } = useParams();
  const navigate = useNavigate();
  const { header, setShowHeader } = React.useContext(Header);

  React.useEffect(() => {
    getLastTransaction();
  }, []);

  const getLastTransaction = () => {
    api.get("Worker/GetLastTransaction?workerID=" + id).then((res) => {
      if (res.data) {
        setStartDate(dayjs(res.data.create_date));
        setEndDate(dayjs(res.data.create_date));
      }
    });
  };

  React.useEffect(() => {
    getData();
  }, [startDate, endDate]);

  const getDataFabric = () => {
    var lstDay = [startDate];
    var start = dayjs(startDate);
    while (start < endDate) {
      start = start.add(1, "day");
      lstDay.push(start);
    }
  };

  const getData = () => {
    var lstDayFabric = [startDate];
    var startFabric = dayjs(startDate);
    while (startFabric < endDate) {
      startFabric = startFabric.add(1, "day");
      lstDayFabric.push(startFabric.format("YYYY/MM/DD"));
    }
    api.post("Worker/GetListTransactionFabric", { WorkerID: id, LstDate: lstDayFabric }).then((res1) => {
      var lstDay = [{ date: startDate }];
      var start = dayjs(startDate);
      while (start < endDate) {
        start = start.add(1, "day");
        lstDay.push({ date: start.format("YYYY/MM/DD") });
      }
      api
        .post("Worker/GetListTransaction", { WorkerID: id, StartDate: startDate ? startDate.format() : null, EndDate: endDate ? endDate.format() : null })
        .then((res) => {
          lstDay.forEach((element) => {
            element.lst = res.data.filter(
              (x) =>
                dayjs(x.create_date).date() == dayjs(element?.date).date() &&
                dayjs(x.create_date).month() == dayjs(element?.date).month() &&
                dayjs(x.create_date).year() == dayjs(element?.date).year()
            );
            var fabric = res1.data.find(
              (x) =>
                dayjs(x.Date).date() == dayjs(element?.date).date() &&
                dayjs(x.Date).month() == dayjs(element?.date).month() &&
                dayjs(x.Date).year() == dayjs(element?.date).year()
            );
            element.lstFabric = fabric?.lstFabric;
          });
          console.log("lstDay", lstDay);
          setListData(lstDay);
        });
    });
  };

  const beforePrint = () => {
    setMode("print");
    setShowHeader(false);
  };
  const afterPrint = () => {
    setMode("web");
    setShowHeader(true);
  };

  return (
    <div>
      {mode == "web" ? (
        <>
          <div>
            <div>
              <div style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
                <div style={{ flex: 1 }}>
                  <DatePicker format={"DD/MM/YYYY"} size="large" style={{ width: "100%" }} onChange={setStartDate} value={startDate} />
                </div>
                <div style={{ display: "flex", margin: 5 }}>
                  <div style={{ margin: "auto" }}>Đến</div>
                </div>
                <div style={{ flex: 1 }}>
                  <DatePicker format={"DD/MM/YYYY"} size="large" style={{ width: "100%" }} onChange={setEndDate} value={endDate} />
                </div>
              </div>
            </div>
          </div>
          <WorkerHistoryPrint listData={listData} type="web" getData={getData} />
          <div style={{ display: "flex", justifyContent: "space-between", position: "fixed", bottom: 20, left: 20, right: 20, gap: 20, zIndex: 1000 }}>
            <Print type="worker_history" listData={listData} style={{ flex: 1 }} header={header} afterPrint={afterPrint} beforePrint={beforePrint} />
            <Button
              type="primary"
              size="large"
              style={{ flex: 1 }}
              onClick={() => {
                scroll.scrollToBottom();
              }}
            >
              End
            </Button>
            <Button
              size="large"
              type="primary"
              onClick={() => {
                navigate("/");
              }}
              style={{ flex: 1 }}
            >
              Home
            </Button>
            <Button
              type="primary"
              size="large"
              style={{ flex: 1 }}
              onClick={() => {
                navigate("/worker/" + id);
              }}
            >
              Trở lại
            </Button>
          </div>
        </>
      ) : (
        <WorkerHistoryPrint listData={listData} header={header} />
      )}
    </div>
  );
};
export default WorkerHistory;
