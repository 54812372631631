import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import FbImageLibrary from "react-fb-image-grid";
import api from "api";
import moment from "moment";
const PostComponent = ({ visible, selectedCmtPost, close }) => {
  const [postInfo, setPostInfo] = useState({});
  const [imagePostInfo, setImagePostInfo] = useState([]);
  const [showtext, setShowText] = useState(false);
  useEffect(() => {
    if (visible) {
      GetPostInfo();
    }
  }, [visible]);

  const GetPostInfo = () => {
    debugger;
    var comment = selectedCmtPost;
    api
      .post("Facebook/GetPostInfo", {
        postid: comment.postid,
        pageid: comment.pageid,
      })
      .then((data) => {
        if (data?.data) {
          var postInfo = JSON.parse(data.data);
          setPostInfo(postInfo);
          getAllImagePostInfo(postInfo);
        }
      });
  };

  const getAllImagePostInfo = (data) => {
    var arrayObj = [];
    var lstImage = [];
    arrayObj = getValueArrayOfObj(data);
    while (arrayObj.length > 0) {
      var obj = arrayObj[0];
      arrayObj.splice(0, 1);
      if (typeof obj != "object") {
        continue;
      }
      var lstKey = getValueArrayOfObj(obj);
      lstKey.forEach((element) => {
        if (element["image"]) {
          lstImage.push(element["image"]);
        } else {
          arrayObj.push(element);
        }
      });
    }
    setImagePostInfo(lstImage);
    console.log(lstImage);
  };

  const getValueArrayOfObj = (data) => {
    return Object.keys(data).map((key, index) => {
      return data[key];
    });
  };

  return (
    <Modal
      title="Thông tin bài viết"
      visible={visible}
      onCancel={close}
      footer={[
        <Button key="cancel" onClick={close}>
          Đóng
        </Button>,
      ]}
    >
      <div style={{ borderBottom: "1px solid #a0a0a0", marginBottom: "5px" }}>
        <div style={{ padding: "15px" }}>
          <div>
            <div>
              <p>
               Ngày tạo: {moment(postInfo.created_time).format("DD/MM/YYYY HH:mm")} 
              </p>

              <p>{postInfo.message}</p>
            </div>
            <div style={{ width: imagePostInfo.length > 1 ? "50%" : "25%", overflow: "hidden" }}>
              {imagePostInfo.length > 0 && (
                <div style={{ height: 100 }}>
                  <FbImageLibrary hideOverlay={true} images={imagePostInfo.map((image) => image.src)} width={40} countFrom={2} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PostComponent;
