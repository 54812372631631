import api from "api";
import React, { useState, useEffect, useCallback } from "react";
import { List, Avatar, Button, Input, Tooltip, message, Badge, Checkbox, Tag } from "antd";
import { MessageFilled, CommentOutlined } from "@ant-design/icons";
import {
  SearchOutlined,
  SyncOutlined,
  FilterOutlined,
  SettingOutlined,
  MessageOutlined,
  PhoneOutlined,
  EnvironmentOutlined,
  TagOutlined,
  EllipsisOutlined,
  PhoneFilled,
  HomeFilled,
  MailOutlined,
} from "@ant-design/icons";
import moment from "moment";
import "./style.css";

const CommentItem = (props) => {
  const { selectedComment, data, lsttag } = props;
  const [tags, setTags] = useState([]);
  useEffect(() => {
    const taglist = data.tag != null ? data.tag.split(",") : [];

    if (taglist.length > 0) {
      const converttag = taglist.map((id) => {
        const tag1 = lsttag.find((tag) => tag.id.toString() === id);
        return {
          id,
          color: tag1?.color,
          name: tag1?.name,
        };
      });
      setTags(converttag);
    }
  }, [data.tag]);
  const clickItem = (item) => {
    if (item.id !== selectedComment?.id) {
      props.onClick(item);
    }
  };

  return (
    <div
      className={`custom-list-item ${data.id === selectedComment?.id ? "selected-comment" : ""}`}
      style={{ backgroundColor: data.customertype }}
      onClick={() => clickItem(data)}
    >
      <div className="avatar-section">
        <div style={{ position: "relative", top: "5px" }}>
          <Badge count={data.unreadcount}>
            <Avatar src={`https://graph.facebook.com/${data.userid}/picture?height=100&width=100&access_token=${data.page_token}`} size="large" />
          </Badge>
          {/* {data.typeof === "inbox" && (
            <MailOutlined
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                fontSize: "15px",
                color: "#0084FF",
                backgroundColor: "white",
                borderRadius: "50%",
                padding: "2px",
              }}
            />
          )} */}
        </div>
      </div>

      <div className="content-section">
        <div className="user-name">{data.username}</div>
        <div className="user-message">{data.childtext}</div>
      </div>
      <div className="action-section">
        <div className="message-time">
          {data.lastupdatetime
            ? moment(data.lastupdatetime).add(7, "hours").isSame(moment(), "day")
              ? moment(data.lastupdatetime).add(7, "hours").format("HH:mm")
              : moment(data.lastupdatetime).add(7, "hours").format("DD-MM HH:mm")
            : moment(data.time).add(7, "hours").isSame(moment(), "day")
            ? moment(data.time).add(7, "hours").format("HH:mm")
            : moment(data.time).add(7, "hours").format("DD-MM HH:mm")}
        </div>
        <div className="action-buttons" style={{ marginTop: "15px" }}>
          <div className="input-icons">
            {tags.map((tag) => (
              <Tag key={tag.id} color={`#${tag.color}`} style={{ minHeight: "10px" }}></Tag>
            ))}
            {data.address && <HomeFilled style={{ color: "#00AEEC", marginRight: "5px"}} />}
            {data.phonenumber && <PhoneFilled style={{ color: "orange" }} />}

            {props.showCheckbox && data.typeof == "inbox" && (
              <Checkbox style={{ marginLeft: "5px" }} checked={props.checked} onChange={(e) => props.onCheckboxChange(data.id, e.target.checked)}></Checkbox>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentItem;
