import React, { useState, useEffect } from "react";
import api from "api";
import { Avatar, Tooltip, message, Popover, Button, Spin, Modal, Row, Col, Input } from "antd";
import * as FacebookAPI from "./FacebookAPI";
import moment from "moment";
import DetailChat from "./detail_chat";

const { TextArea } = Input;

const ModalMessage = (props) => {

    const [selectedComment, setSelectedComment] = useState({});
    useEffect(() => {
        if (props.visible) {
            getInbox();
        }
    }, [props.visible]); 
    const getInbox = () => {
        if (props.cmt && props.cmt.userid && props.cmt.pageid) {
            api.get("/Facebook/GetInboxByUser?userID=" + props.cmt.userid + "&pageID=" + props.cmt.pageid).then((res) => {
                setSelectedComment(res.comment);
                
            }).catch(err => {
               
            });
        }
    };

    const handleClose = () => {
        props.closeModalMessage();
    };

    const onTagChange = () => {
      };
    const type = props.cmt && !props.cmt.issendinbox ? "private" : selectedComment.id ? "inbox" : "private";

    return (
        <Modal
            centered={true}
            visible={props.visible}
            onCancel={handleClose}
            title={
                <span>
                    Nhắn tin tới: <b>{props.cmt.username}</b>
                </span>
            }
            width="50%"
            footer={null}
        >
            <div>
                <span style={{ paddingTop: "10px" }}>
                    <DetailChat     key={8386}   onTagChange={onTagChange}  selectedComment={selectedComment} curentComment={props.cmt} from={"comment"} type={type} height={window.innerHeight - 200} />
                </span>
            </div>
        </Modal>
    );
};

export default ModalMessage;
