import React, { useState, useEffect } from "react";
import { Modal, Input, Button, message, Table, Form, Upload, Spin } from "antd";
import api from "api";
import { CheckOutlined, PictureOutlined } from "@ant-design/icons";
import { toNoUnicode } from "common/Text/index";
import { imageURL } from "config/index";
import { uploadImageURL } from "config/index";
import axios from "axios";
const { TextArea } = Input;

const Template = ({ visible, onCancel, onApply }) => {
    const form = React.useRef();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lstdata, setListData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if (visible) {
            getMessage();
        }
    }, [visible]);

    const getMessage = async () => {
        try {
            const res = await api.get("/Facebook/GetListMessageTemplate");
            setListData(res.lst);
        } catch (err) {
            message.error("Failed to fetch message templates");
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };
    const chooseUploadChange = (info) => {
        debugger
        if (fileList.length > 10) {
            message.error("Chỉ được tải lên tối đa 10 hình ảnh!");
        }
        const file = info.file.originFileObj;
        uploadImage(file);
    };
    const uploadImage = async (file) => {
        const fmData = new FormData();
        const config = {
            headers: { "content-type": "multipart/form-data" },
            withCredentials: true,
        };
        fmData.append("file", file);
        fmData.append("type", "image");
        try {
            const res = await axios.post(uploadImageURL, fmData, config);
            const newFile = {
                uid: fileList.length.toString(),
                name: res.data,
                url: `${imageURL}${res.data}`,
            };

            const fileExists = fileList.some((item) => item.uid === newFile.uid);
            if (!fileExists) {
                const newFileList = [newFile, ...fileList];
                setFileList(newFileList);
            }
        } catch (err) { }
    };
    const onFinish = (values) => {
        console.log(values);
        const { id, content } = values;
        if (fileList.length > 0) {
            debugger
            var listImage = fileList.map((item, index) => {
                return item.url;
            });
            values.content += JSON.stringify({ images: listImage });
        }
        setLoading(true);
        api
            .post("Facebook/UpdateMessageTemplate", values)
            .then((res) => {
                message.success("Lưu thành công");
                setIsModalVisible(false);
                form.current.resetFields();
                setFileList([]);
                getMessage();
                setLoading(false);
            })
            .catch((err) => {
                message.error(err.message);
                setLoading(false);
            });
    };


    const handleCancel = () => {
        setIsModalVisible(false);
        form.current.resetFields();
        setFileList([]);
        setSearchText("");
    };

    const keyChange = (e) => {
        const { value } = e.target;
        const newvalue = toNoUnicode(value);
        form.current.setFieldsValue({ id: newvalue });
    };

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            key: "id",
            width: 50,
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
            render: (code,content) => <Button type="primary" onClick={() => { onApply(content.content) }} >{code}</Button>,
        },
        {
            title: "Content",
            dataIndex: "content",
            key: "content",
            ellipsis: true,
        },
    ];


    const insertTag = (tag) => {
        const currentValue = form.current.getFieldValue("content") || "";
        form.current.setFieldsValue({ content: currentValue + tag });
    };

    const filteredData = lstdata.filter((item) =>
        item.content.toLowerCase().includes(searchText.toLowerCase()) ||
        item.code.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <Modal
                title="Tin nhắn mẫu"
                visible={visible}
                onCancel={onCancel}
                footer={null}
                width={"60%"}
            >
                <Input
                    placeholder="Tìm kiếm"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ marginBottom: "10px" }}
                />
                <Table
                    dataSource={filteredData}
                    columns={columns}
                    pagination={false}
                    rowClassName={() => "custom-row"}
                />
                <p style={{ color: "red", marginTop: "10px" }}>
                    {`{namefb}`}: Thay tên facebook
                </p>
                {/* <p style={{ color: "red" }}>
                    {`{namepage}`}: Thay tên Page
                </p> */}
                <Button type="dashed" onClick={showModal}>
                    Thêm tin nhắn mẫu
                </Button>
            </Modal>

            <Modal
                title="Tạo tin nhắn mẫu"
                visible={isModalVisible}
                onOk={() => {
                    form.current.submit();
                }}
                onCancel={handleCancel}
                width={"40%"}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Hủy
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={() => {
                        form.current.submit();
                    }}>
                        {loading ? <Spin size="small" /> : "Lưu"}
                    </Button>,
                ]}
            >
                <Form layout="vertical" ref={form} onFinish={onFinish}>
                    <Form.Item
                        label="Từ khóa"
                        name="code"
                        rules={[{ required: true, message: "Vui lòng nhập từ khóa!" }]}
                    >
                        <Input placeholder="Nhập từ khóa" onChange={keyChange} />
                    </Form.Item>
                    <Form.Item
                        label="Nội dung chính"
                        name="content"
                        rules={[
                            { required: true, message: "Vui lòng nhập nội dung chính!" },
                            { max: 2000, message: "Nội dung không được vượt quá 2000 ký tự!" },
                        ]}
                    >
                        <TextArea placeholder="Nhập nội dung chính tối đa 2000 ký tự!" rows={4} />
                    </Form.Item>

                    <div style={{ marginBottom: 16 }}>
                        <Button type="dashed" onClick={() => insertTag("{namefb}")}>
                            Họ và tên
                        </Button>
                        {/* <Button type="dashed" onClick={() => insertTag("{namepage}")} style={{ marginLeft: 8 }}>
                            Tên fanpage
                        </Button> */}
                    </div>
                    <Form.Item label="Thêm hình ảnh">
                        <Upload fileList={fileList} listType="picture-card" onChange={chooseUploadChange} accept="image/*">
                            <Button icon={<PictureOutlined />} type="text" />
                        </Upload>
                        <p style={{ color: "gray" }}>
                            Vui lòng nhấn chọn hình ảnh đính kèm tin nhắn nhanh. Tối đa 10 hình ảnh!
                        </p>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default Template;
