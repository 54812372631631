import React from "react";
import { message, Modal } from "antd";
import FacebookLogin from "react-facebook-login";
import api from "api";

const FbSync = ({ visible, onCancel }) => {
  const responseFacebook = (response) => {
    if (response.error) {
    } else {
      exchangeToken(response.accessToken);
    }
  };

  const exchangeToken = (token) => {
    api.get("Facebook/ExchangeToken?oldToken=" + token).then((res) => {});
    message.success("Đồng bộ thành công")

  };

  return (
    <Modal
      title="Đồng bộ Page"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{
        padding: "40px",
        backgroundColor: "#f7f9fc",
        borderRadius: "10px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      }}
      titleStyle={{
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "20px",
        marginBottom: "20px",
      }}
      centered
    >
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <FacebookLogin
          appId="2194958603892247"
          version="16.0"
          scope="email,public_profile,pages_manage_metadata,pages_messaging,pages_read_engagement,leads_retrieval,pages_manage_posts,pages_show_list,business_management,page_events,pages_read_user_content,pages_manage_ads,pages_manage_engagement"
          callback={responseFacebook}
          buttonStyle={{
            padding: "10px 20px",
            fontSize: "14px",
            borderRadius: "8px",
            backgroundColor: "#4267B2",
            color: "white",
          }}
        />
      </div>
    </Modal>
  );
};

export default FbSync;
