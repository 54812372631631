import React, { useState, useRef, useEffect } from "react";
import { Tooltip, Popover, Tag, Button, Row, Col, Badge, Space, Input, Select } from "antd";
import "./sidebar.css";
import { Icon } from "@ant-design/compatible";
import {
  PhoneOutlined,
  FileExcelOutlined,
  CommentOutlined,
  MailOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  ProductOutlined,
  FileDoneOutlined,
  WarningOutlined,
  TagsOutlined,
  FacebookOutlined,
  SettingOutlined,
  OrderedListOutlined,
  CloseCircleOutlined,
  VideoCameraAddOutlined,
  CheckCircleOutlined,
  UserOutlined,
  UserSwitchOutlined

} from "@ant-design/icons";
import api from "api";
import FbSync from "./facebooksync";

const { Search } = Input;
const Sidebar = (props) => {
  const [showSelectPage, setShowSelectPage] = useState(false);
  const [showSelectTag, setShowSelectTag] = useState(false);
  const [showSelectDate, setShowSelectDate] = useState(false);
  const [showSelectPost, setShowSelectPost] = useState(false);
  const [lstPost, setLstPost] = useState([]);
  const [lstTagChat, setLstTagChat] = useState([]);
  const [lstPageData, setLstPageData] = useState([]);
  const [listPageSearch, setListPageSearch] = useState([]);
  const [isShowFbSync, setIsShowFbSync] = useState(false);
  const handleSelectPageVisibleChange = (visible) => setShowSelectPage(visible);
  const handleSelectTagVisibleChange = (visible) => setShowSelectTag(visible);
  const handleSelectDateVisibleChange = (visible) => setShowSelectDate(visible);
  const handleSelectPostVisibleChange = (visible) => setShowSelectPost(visible);
  useEffect(() => {
    // getCurrentTag();
    // getCurrentPage();
    getAllPost();
  }, []);

  const getAllPost = () => {
    api.get("Facebook/GetAllPost").then((res) => {
      var data = res.data.map((item) => {
        return {
          value: item.id,
          label: item.live_content
        };
      });
      setLstPost(data);

    });
  };
  const getCurrentTag = () => {
    api.get("Facebook/GetCurrentTag").then((res) => {
      setLstTagChat(res.data);
    });
  };
  const getCurrentPage = () => {
    api.get("Facebook/GetCurrentPage").then((res) => {
      setLstPageData(res.data);
      setListPageSearch(res.data);
    });
  };
  const isNotFilter = () => {
    return (
      props.objectSearch.pageSelect.length == 0 &&
      props.objectSearch.tag == "" &&
      props.objectSearch.typemessage == "" &&
      props.objectSearch.begintime == "" &&
      props.objectSearch.haveread == "" &&
      props.objectSearch.havereply == "" &&
      props.objectSearch.havephone == "" &&
      props.objectSearch.haveorder == "" &&
      props.objectSearch.customertype == "" &&
      props.objectSearch.post == ""
    );
  };

  const renderPost = () => {

    const clearPostSearch = () => {
      setShowSelectPost(false);
      props.updateObjectSearch("post", "");
    };
    return (
      <div style={{ maxWidth: "300px", minWidth: "300px" }}>
        <Select placeholder={"Chọn phiên live"} style={{ width: "100%" }} options={lstPost} onChange={onSelectPost} showSearch allowClear optionFilterProp="label" />
        <div style={{ marginTop: "10px" }}>

          <div style={{ textAlign: "right" }}>
            <Button
              style={{
                marginLeft: "15px",
                padding: "4px 8px",
                fontSize: "12px",
                height: "auto",
              }}
              type="primary"
              onClick={() => {
                setShowSelectPost(false);
              }}
            >
              Đóng
            </Button>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  };
  const onSelectPost = (value) => {
    if (value)
      props.updateObjectSearch("post", value);
    else
      props.updateObjectSearch("post", "");
  };
  const onTagSelected = (id) => {
    debugger;
    if (id == "NOTAG") {
      var index = props.objectSearch.tag;
      if (index == "NOTAG") {
        props.updateObjectSearch("tag", "");
      } else {
        props.updateObjectSearch("tag", id);
      }
    } else {
      var arrayTagSelected = props.objectSearch.tag.toString().split(",");
      var index = arrayTagSelected.findIndex((tag) => tag == "NOTAG" || tag == "");
      if (index >= 0) {
        arrayTagSelected.splice(index, 1);
      }
      index = arrayTagSelected.findIndex((tag) => tag == id);
      if (index < 0) {
        arrayTagSelected.push(id);
      } else {
        arrayTagSelected.splice(index, 1);
      }

      props.updateObjectSearch("tag", arrayTagSelected.join(","));
    }
  };
  const renderShowTag = () => {
    var lstTag = lstTagChat.map((item, index) => {
      var opacity = 0.3;
      var arrayTagSelected = props.objectSearch.tag.toString().split(",");

      if (arrayTagSelected.findIndex((tag) => tag == item.id) >= 0) {
        opacity = 1;
      }
      return (
        <Tag
          key={"tag_select_" + item.id}
          color={"#" + item.color}
          style={{ opacity: opacity, marginBottom: "15px", cursor: "pointer" }}
          onClick={() => onTagSelected(item.id)}
        >
          {item.name}
        </Tag>
      );
    });
    var opacity = 0.3;
    var arrayTagSelected = props.objectSearch.tag.toString().split(",");

    if (arrayTagSelected.findIndex((tag) => tag == "NOTAG") >= 0) {
      opacity = 1;
    }
    var noTag = (
      <Tag
        key={"tag_select_NOTAG"}
        color={"#e80000"}
        style={{ opacity: opacity, marginBottom: "15px", cursor: "pointer" }}
        onClick={() => onTagSelected("NOTAG")}
      >
        Không Nhãn
      </Tag>
    );
    const clearTagSearch = () => {
      setShowSelectTag(false);
      props.updateObjectSearch("tag", "");
    };
    return (
      <div style={{ maxWidth: "400px", minWidth: "400px" }}>
        {lstTag}
        <div style={{ marginTop: "10px" }}>
          <div>{noTag}</div>
          <div style={{ textAlign: "right" }}>
            <Button onClick={clearTagSearch} style={{ padding: "4px 8px", fontSize: "12px", height: "auto" }}>
              Bỏ chọn
            </Button>
            <Button
              style={{
                marginLeft: "15px",
                padding: "4px 8px",
                fontSize: "12px",
                height: "auto",
              }}
              type="primary"
              onClick={() => {
                setShowSelectTag(false);
              }}
            >
              Đóng
            </Button>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  };
  const renderShowPage = () => {
    var lstPage = listPageSearch.map((item, index) => {
      var page = (
        <div style={{ padding: "10px" }}>
          <Tooltip placement="top" title={item.page_id + " - " + item.page_name}>
            <img
              style={{ width: "100%", height: "auto" }}
              src={"https://graph.facebook.com/" + item.page_id + "/picture?height=250&width=250&access_token=" + item.page_token}
            ></img>
            <div
              style={{
                marginTop: "10px",
                textAlign: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                whiteSpace: "nowrap",
              }}
            >
              <b> {item.page_name}</b>
            </div>
          </Tooltip>
        </div>
      );
      var isSelected = false;
      var index = props.objectSearch.pageSelect.findIndex((p) => {
        return item.page_id == p;
      });
      if (index >= 0) {
        isSelected = true;
      }
      return (
        <div
          key={"select_page_" + item.page_id}
          style={{ float: "left", marginRight: "15px", marginBottom: "15px", paddingTop: "15px" }}
          onClick={() => {
            pageClick(item.page_id);
          }}
        >
          {isSelected ? (
            <Badge
              count={
                <div style={{ backgroundColor: "white" }}>
                  <Icon type="check-circle" theme="twoTone" twoToneColor="#3097D1" />
                </div>
              }
            >
              <div
                style={{
                  width: "125px",
                  height: "150px",
                  display: "block",
                  border: "1px solid #3097D1",
                  borderRadius: "5px",
                }}
              >
                {page}
              </div>
            </Badge>
          ) : (
            <div
              style={{
                width: "125px",
                height: "150px",
                display: "block",
                border: "1px solid #ddd",
                borderRadius: "5px",
              }}
            >
              {page}
            </div>
          )}
        </div>
      );
    });


    const selectAllPage = () => {
      var lstPage = listPageSearch.map((item) => item.page_id);
      props.updateObjectSearch("pageSelect", lstPage);
    };

    const pageClick = (id) => {
      var index = props.objectSearch.pageSelect.findIndex((p) => {
        return id == p;
      });
      var newList = props.objectSearch.pageSelect;
      if (index < 0) {
        newList.push(id);
      } else {
        newList.splice(index, 1);
      }
      props.updateObjectSearch("pageSelect", newList);
    };
    return (
      <div>
        <div style={{ maxHeight: "450px", maxWidth: "750px", overflowY: "scroll" }}>{lstPage}</div>

        <div style={{ marginTop: "20px", maxWidth: "750px", minWidth: "400px" }}>
          <Row>
            <Col span={12}>
              <Search placeholder="Page ID" allowClear onSearch={searchPageByPageID} />
            </Col>
            <Col span={4}>
              <div style={{ marginLeft: "10px" }}></div>
            </Col>
            <Col span={8}>
              <div className="pull-right">
                <Space size={"small"}>
                  <Button onClick={clearPageSearch}>Bỏ chọn</Button>
                  <Button onClick={selectAllPage}>Tất cả</Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowSelectPage(false);
                    }}
                  >
                    Đóng
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  };

  const clearPageSearch = () => {
    setShowSelectPage(false);
    props.updateObjectSearch("pageSelect", []);
  };

  const searchPageByPageID = (value) => {
    if (value != "") {
      var page = lstPageData.filter((x) => x.page_id == value);
      setListPageSearch(page);
    } else {
      setListPageSearch(lstPageData);
    }
  };
  const cancelFbSync = () => {
    setIsShowFbSync(false);
    // getCurrentPage();
  };
  const handleKeyPressPostID = (e) => {
    if (e.which === 13) {
      props.getListFBComment();
    }
  };

  return (
    <>
      <div className="sidebar2">
        <ul className="sidebar-nav" style={{ fontSize: "25px", height: "100%", display: "table", textAlign: "center" }}>
          <Tooltip placement="right" title={"Tất cả"}>
            <li onClick={() => props.clearFilterChat()} className={isNotFilter() ? "sidebar-selected" : ""}>
              <ProductOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>

          {/* <Popover
            placement="right"
            title={"Page"}
            content={renderShowPage()}
            trigger="click"
            visible={showSelectPage}
            onVisibleChange={handleSelectPageVisibleChange}
          >
            <Tooltip placement="right" title={"Page"}>
              <li className={props.objectSearch.pageSelect.length > 0 ? "sidebar-selected" : ""}>
                <FacebookOutlined style={{ color: "#757575" }} />
              </li>
            </Tooltip>
          </Popover>

          <Popover
            placement="right"
            title={"Nhãn"}
            content={renderShowTag()}
            trigger="click"
            visible={showSelectTag}
            onVisibleChange={handleSelectTagVisibleChange}
          >
            <Tooltip placement="right" title={"Nhãn"}>
              <li className={props.objectSearch.tag !== "" ? "sidebar-selected" : ""}>
                <TagsOutlined style={{ color: "#757575" }} />
              </li>
            </Tooltip>
          </Popover>
          <Tooltip placement="right" title={"Inbox"}>
            <li
              onClick={() => props.updateObjectSearch("typemessage", "inbox")}
              className={props.objectSearch.typemessage === "inbox" ? "sidebar-selected" : ""}
            >
              <MailOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>

          <Tooltip placement="right" title={"Comment"}>
            <li
              onClick={() => props.updateObjectSearch("typemessage", "comment")}
              className={props.objectSearch.typemessage === "comment" ? "sidebar-selected" : ""}
            >
              <CommentOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>

          <Tooltip placement="right" title={"Chưa đọc"}>
            <li onClick={() => props.updateObjectSearch("haveread", "unread")} className={props.objectSearch.haveread === "unread" ? "sidebar-selected" : ""}>
              <EyeInvisibleOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>

          <Tooltip placement="right" title={"Đã đọc"}>
            <li onClick={() => props.updateObjectSearch("haveread", "read")} className={props.objectSearch.haveread === "read" ? "sidebar-selected" : ""}>
              <EyeOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>

          <Tooltip placement="right" title={"Đã trả lời"}>
            <li onClick={() => props.updateObjectSearch("havereply", "reply")} className={props.objectSearch.havereply === "reply" ? "sidebar-selected" : ""}>
              <FileDoneOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>
          <Tooltip placement="right" title={"Chưa trả lời"}>
            <li
              onClick={() => props.updateObjectSearch("havereply", "unreply")}
              className={props.objectSearch.havereply === "unreply" ? "sidebar-selected" : ""}
            >
              <FileExcelOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>

          <Tooltip placement="right" title={"Không có SĐT"}>
            <li
              onClick={() => props.updateObjectSearch("havephone", "nophone")}
              className={props.objectSearch.havephone === "nophone" ? "sidebar-selected" : ""}
            >
              <WarningOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>
          <Tooltip placement="right" title={"Có SĐT"}>
            <li
              onClick={() => props.updateObjectSearch("havephone", "havephone")}
              className={props.objectSearch.havephone === "havephone" ? "sidebar-selected" : ""}
            >
              <PhoneOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip> */}
          <Popover
            placement="right"
            title={"Lọc theo bài viết"}
            content={renderPost()}
            trigger="click"
            visible={showSelectPost}
            onVisibleChange={handleSelectPostVisibleChange}
          >
            <Tooltip placement="right" title={"Lọc theo bài viết"}>
              <li className={props.objectSearch.post !== "" ? "sidebar-selected" : ""}>
                <VideoCameraAddOutlined style={{ color: "#757575" }} />
              </li>
            </Tooltip>
          </Popover>
          <Tooltip placement="right" title={"Có đơn hàng"}>
            <li
              onClick={() => props.updateObjectSearch("haveorder", "haveorder")}
              className={props.objectSearch.haveorder === "haveorder" ? "sidebar-selected" : ""}
            >
              <CheckCircleOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>
          <Tooltip placement="right" title={"Không có đơn hàng"}>
            <li
              onClick={() => props.updateObjectSearch("haveorder", "noorder")}
              className={props.objectSearch.haveorder === "noorder" ? "sidebar-selected" : ""}
            >
              <CloseCircleOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>
          <Tooltip placement="right" title={"Khách sỉ"}>
            <li
              onClick={() => props.updateObjectSearch("customertype", "wholesale")}
              className={props.objectSearch.customertype === "wholesale" ? "sidebar-selected" : ""}
            >
              <UserSwitchOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>
          <Tooltip placement="right" title={"Khách lẻ"}>
            <li
              onClick={() => props.updateObjectSearch("customertype", "retail")}
              className={props.objectSearch.customertype === "retail" ? "sidebar-selected" : ""}
            >
              <UserOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>
          {/* <Tooltip placement="right" title={"Bom hàng"}>
            <li
              onClick={() => props.updateObjectSearch("customertype", "bombing")}
              className={props.objectSearch.customertype === "bombing" ? "sidebar-selected" : ""}
            >
              <WarningOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip> */}

          <Tooltip placement="right" title={"Đồng bộ Page"}>
            <li
              className="setting"
              style={{ display: "table-row", verticalAlign: "bottom", height: 50, lineHeight: "1", fontSize: 28 }}
              onClick={() => setIsShowFbSync(true)}
            >
              <SettingOutlined style={{ color: "#757575" }} />
            </li>
          </Tooltip>
        </ul>
      </div>
      <FbSync visible={isShowFbSync} onCancel={cancelFbSync} />
    </>
  );
};

export default Sidebar;
