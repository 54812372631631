import { realtimeUrl } from "config";
class SignalRReatime {
  constructor() {
    this.refID = 1;
    this.ref = [];
    this.start();
  }

  start = () => {
    var connection = window.$.hubConnection();
    connection.url = realtimeUrl;
    var contosoChatHubProxy = connection.createHubProxy("notificationHub");
    contosoChatHubProxy.on("addNewMessageToClient", (name, message) => {
      this.ref.forEach((ref) => {
        if (ref.path == name) {
          ref.action(JSON.parse(message));
        }
      });
    });
    connection
      .start()
      .done(function () {
        console.log("Now connected, connection ID=" + connection.id);
      })
      .fail(function () {
        console.log("Could not connect");
      });
  };

  subscribe(path, action) {
    var oldSub = this.ref.find((x) => x.path == path);
    if (oldSub) oldSub.action = action;
    else {
      this.refID++;
      var obj = {
        id: this.refID,
        path: path,
        action: action,
      };
      this.ref.push(obj);
    }

    return this.refID;
  }

  unSubscribe(id) {
    var index = this.ref.findIndex((item) => {
      return item.id == id;
    });
    if (index >= 0) {
      this.ref.splice(index, 1);
    }
  }
}

export default SignalRReatime;
