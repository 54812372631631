import React from "react";
import { Card, Button, Select, message, Popconfirm, Col, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import api from "api";
import PopupInsert from "./popup_insert";
import PopupShowDebtCustomer from "./popup_show_debt_customer";
import { Header } from "Client";
import { createSearchText } from "common/Text";

const Home2 = (props) => {
  const [customer, setCustomer] = React.useState();
  const [worker, setWorker] = React.useState();
  const [source, setSource] = React.useState();
  const [lstCustomer, setLstCustomer] = React.useState([]);
  const [cusBirthDays, setCusBirthdays] = React.useState([]);
  const [lstSupplier, setLstSupplier] = React.useState([]);
  const [lstWorker, setLstWorker] = React.useState([]);
  const [loadingCustomer, setLoadingCustomer] = React.useState(true);
  const [loadingSupplier, setLoadingSupplier] = React.useState(true);
  const [loadingWorker, setLoadingWorker] = React.useState(true);
  const [lstCustomerDebt, setLstCustomerDebt] = React.useState([]);
  const [type, setType] = React.useState();

  const popupInsert = React.useRef();
  const popupShowDebtCustomer = React.useRef();
  const { userLogin, header } = React.useContext(Header);
  console.log("userLogin", userLogin);
  const navigate = useNavigate();

  React.useEffect(() => {
    getListCustomer();
    getListSupplier();
    getListWorker();
    api.get("Customer/GetCustomerWithBirthDayToDay").then((res) => {
      setCusBirthdays(res.data);
    });
    api.get("Customer/GetCustomerDebt").then((res) => {
      setLstCustomerDebt(res.data);
    });
  }, []);

  const getListCustomer = () => {
    api.get("Customer/GetList").then((res) => {
      setLstCustomer(res.data);
      setLoadingCustomer(false);
    });
  };

  const getListSupplier = () => {
    api.get("Supplier/GetList").then((res) => {
      setLstSupplier(res.data);
      setLoadingSupplier(false);
    });
  };

  const getListWorker = () => {
    api.get("Worker/GetList").then((res) => {
      setLstWorker(res.data);
      setLoadingWorker(false);
    });
  };

  const deleteItem = (type, id) => {
    api.get(`${type}/Delete?id=${id}`).then((res) => {
      message.success("Xóa thành công");
      if (type == "Supplier") {
        setSource(null);
        getListSupplier();
      } else {
        setWorker(null);
        getListWorker();
      }
    });
  };

  return (
    <div>
      <div style={{ marginBottom: 20, fontWeight: "bold" }}>
        <div>{`Chào mừng ${userLogin.position && userLogin.position != "" ? userLogin.position : ""} ${userLogin.name}.`}</div>
        <div>Chúc bạn 1 ngày làm việc hiệu quả, bạn muốn làm gì?</div>
      </div>
      <Card>
        <div style={{ display: "flex", gap: 20 }}>
          <>
            <Button
              type="primary"
              size="large"
              style={{ flex: 1 }}
              onClick={() => {
                navigate("/live_customer");
              }}
            >
              Khách hàng
            </Button>
            <Button
              type="primary"
              size="large"
              style={{ flex: 1 }}
              onClick={() => {
                navigate("/print_order");
              }}
            >
              In đơn
            </Button>
            <Button
              type="primary"
              size="large"
              style={{ flex: 1 }}
              onClick={() => {
                navigate("/orders");
              }}
            >
              Đơn hàng
            </Button>
            <Button
              type="primary"
              size="large"
              style={{ flex: 1 }}
              onClick={() => {
                navigate("/chat");
              }}
            >
              Chat
            </Button>
          </>
        </div>
      </Card>
      <PopupInsert ref={popupInsert} getListSupplier={getListSupplier} getListWorker={getListWorker} />
      <PopupShowDebtCustomer ref={popupShowDebtCustomer}></PopupShowDebtCustomer>
    </div>
  );
};
export default Home2;
