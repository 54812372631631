import api from "api";



const fbUrl = "https://graph.facebook.com/v16.0";

export const replyInboxAPI = (psid, token, text, isRetryTag) => {
  var url = "";
  url = `/me/messages?access_token=${token}`;
  var formData = new FormData();
  var recipient = { id: psid };
  var message = {};
  if (text != "") {
    message.text = text;
  }
  formData.append("recipient", JSON.stringify(recipient));
  formData.append("message", JSON.stringify(message));
  try {
    var params = {
      method: "POST",
      body: formData,
    };
    return fetch(fbUrl + url, params).then(handleResponse, handleError);
  } catch (e) {
  }
};

export const replyFbAPI = (commentid, pageid, token, message, type, tagChat, userfbid) => {
  
  var IsThan24h = tagChat != null ? true : false;

  var url = "";
  message = message.replace(/\n/g, "\n");
  var myJSON = null;
  if (type === "comment") {
    url = `/${commentid}/comments?access_token=${token}`;
    var formData = new FormData();
    formData.append("access_token", token);
    formData.append("message", message);
    try {
      var params = {
        method: "POST",
        body: formData,
      };
      return fetch(fbUrl + url, params).then(handleResponse, handleError);
    } catch (e) {
    }
  } else {
    if (IsThan24h) {
      myJSON = {
        recipient: { id: userfbid },
        message: { text: message },
        messaging_type: "MESSAGE_TAG",
        tag: tagChat,
      };
    } else {
      myJSON = { recipient: { id: userfbid }, message: { text: message } };
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(myJSON),
    };
    var inbox = commentid.replace("-" + pageid, "");
    url = "/me/messages?access_token=" + token;

    return fetch(fbUrl + url, requestOptions).then(handleResponse, handleError);
  }
};

const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

const toDataURLs = (urls) => {
  var lst = [];
  urls.forEach((url) => {
    lst.push(toDataURL(url));
  });
  return Promise.all(lst);
};

const dataURItoBlob = (dataURI, type) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: type });
};

const handleResponse = (response) => {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then((json) => resolve(json));
      } else {
        resolve();
      }
    } else {
      response.text().then((text) => reject(text));
    }
  });
};
const handleError = (error) => {
  return Promise.reject(error && error.message);
};

const detectTypePlayload = function (imgUrl) {
  let ext = imgUrl.substring(imgUrl.lastIndexOf(".") + 1).toLowerCase();
  var arrVideoExt = ["avi", "flv", "wmv", "mov", "mp4"];
  var arrAudioExt = ["mp3", "wma", "wav", "flac", "aac", "ogg", "aiff"];
  if (imgUrl.includes("audio") || arrAudioExt.includes(ext)) {
    return "audio";
  }

  if (arrVideoExt.includes(ext)) {
    return "video";
  }
  return "image";
};

export const replyInboxWithPhoto = (commentid, pageid, token, message, photos, tagChat, userfbid) => {

  photos = encodeURI(photos.url);
  console.log(photos);
  return toDataURL(photos).then((dataUrl) => {
    var fileType = "";
    var ext = "";
    var blob = "";
    if (fileType == "video") {
      ext = dataUrl.substring("data:video/".length, dataUrl.indexOf(";base64"));
      blob = dataURItoBlob(dataUrl, "video/" + ext);
    } else {
      ext = dataUrl.substring("data:image/".length, dataUrl.indexOf(";base64"));
      blob = dataURItoBlob(dataUrl, "image/" + ext);
    }
    var IsThan24h = tagChat != null ? true : false;
    var jsondata = null;
    var type = detectTypePlayload(photos);
    var url = "/me/messages?access_token=" + token;
    var formData = new FormData();
    formData.append("recipient", '{id:"' + userfbid + '"}');
    formData.append("message", '{"attachment":{"type": "' + type + '", "payload":{"is_reusable":true}}}');

    formData.append("attachments", blob);

    if (IsThan24h) {
      formData.append("messaging_type", "MESSAGE_TAG");
      formData.append("tag", tagChat);
    }

    const requestOptions = {
      method: "POST",
      body: formData,
    };
    return fetch(fbUrl + url, requestOptions).then(handleResponse, handleError);
  });
};

export const replyWithPhotoData = (commentid, pageid, token, message, photo, type) => {
  photo = encodeURI(photo.url);
  console.log(photo);
  return toDataURL(photo).then((dataUrl) => {
  var fileType = "";
    var ext = "";
    var blob = "";
    if (fileType == "video") {
      ext = dataUrl.substring("data:video/".length, dataUrl.indexOf(";base64"));
      blob = dataURItoBlob(dataUrl, "video/" + ext);
    } else {
      ext = dataUrl.substring("data:image/".length, dataUrl.indexOf(";base64"));
      blob = dataURItoBlob(dataUrl, "image/" + ext);
    }

    var url =
      type === "comment"
        ? `/${commentid}/comments?access_token=${token}`
        : `/${commentid.replace("-" + pageid, "")}/messages?access_token=${token}`;
    var formData = new FormData();
    formData.append("access_token", token);
    if (type === "comment") {
      formData.append("source", blob);
    } else {
      formData.append("attachments", blob);
    }
    if (message != "") formData.append("message", message);
    try {
      var params = {
        method: "POST",
        body: formData,
      };
      return fetch(fbUrl + url, params).then(handleResponse, handleError);
    } catch (e) {
      console.log(e);
    }
  });
};
export const likeAction = (commentid, mode, token) => {
  var method = mode === true ? "POST" : "DELETE";
  var url = `/${commentid}/likes?access_token=${token}`;
  var params = {
    method: method,
  };
  return fetch(fbUrl + url, params).then(handleResponse, handleError);
};
export const hideAction = (commentid, mode, token) => {
  var url = `/${commentid}?is_hidden=${mode}&access_token=${token}`;
  var params = {
    method: "POST",
  };
  return fetch(fbUrl + url, params).then(handleResponse, handleError);
};
export const deleteAction = (commentid, token) => {
  var url = `/${commentid}?access_token=${token}`;
  var params = {
    method: "DELETE",
  };
  return fetch(fbUrl + url, params).then(handleResponse, handleError);
};


export const privateMessage = (commentid, message, token) => {
  message = message.replace(/\n/g, "\n");

  var url = "/me/messages?access_token=" + token;
  var myJSON = {
    recipient: { comment_id: commentid },
    message: { text: message },
  };

  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(myJSON),
  };
  return fetch(fbUrl + url, requestOptions).then(handleResponse, handleError);
};

export const checkblockAction = (userid, token, mode) => {
  var url = `/${"me/blocked?asid=[" + userid + "]"}&access_token=${token}`;
  return fetch(fbUrl + url).then(handleResponse, handleError);
};

export const blockAction = (userid, token, mode) => {
  var method = mode === true ? "POST" : "DELETE";
  var url =
    mode === true
      ? `/${"me/blocked?asid=[" + userid + "]"}&access_token=${token}`
      : `/${"me/blocked?user=" + userid}&access_token=${token}`;
  var params = {
    method: method,
  };
  return fetch(fbUrl + url, params).then(handleResponse, handleError);
};

export const getLinkInbox = (ID, token) => {
  var url = `/${ID}?access_token=${token}`;
  return fetch("https://graph.facebook.com" + url).then(handleResponse, handleError);
};

export const getThreadID = (userid, token) => {
  var url = "/me/conversations?user_id=" + userid + "&access_token=" + token;
  var params = {
    method: "GET",
  };
  return fetch(fbUrl + url, params).then(handleResponse, handleError);
};

export const sendRequestInfo = (userID, token, pageID, tagChat, setting, trackingID) => {
  var IsThan24h = tagChat != null ? true : false;
  var url = "/me/messages?access_token=" + token;

  var jsondata = {
    recipient: { id: userID },
    message: {
      attachment: {
        type: "template",
        payload: {
          template_type: "button",
          text: setting.W_AddressQuestionTitle,
          buttons: [
            {
              type: "web_url",
              url: `https://donhang.io/khachhang.html?userID=${userID}&pageID=${pageID}&trackingID=${trackingID}`,
              title: setting.W_AddressQuestionButton,
              messenger_extensions: "TRUE",
              //webview_height_ratio: "FULL",
            },
          ],
        },
      },
    },
  };

  if (IsThan24h) {
    jsondata.messaging_type = "MESSAGE_TAG";
    jsondata.tag = tagChat;
  }

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(jsondata),
  };
  return fetch(fbUrl + url, requestOptions).then(handleResponse, handleError);
};

export const sendRequestPhone = (userID, token, pageID, tagChat, text) => {
  var IsThan24h = tagChat != null ? true : false;
  var url = "/me/messages?access_token=" + token;

  var jsondata = {
    recipient: { id: userID },
    messaging_type: "RESPONSE",
    message: {
      text: text,
      quick_replies: [
        {
          content_type: "user_phone_number",
        },
      ],
    },
  };

  if (IsThan24h) {
    jsondata.messaging_type = "MESSAGE_TAG";
    jsondata.tag = tagChat;
  }

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(jsondata),
  };
  return fetch(fbUrl + url, requestOptions).then(handleResponse, handleError);
};

export const sendRequestEmail = (userID, token, pageID, tagChat, text) => {
  var IsThan24h = tagChat != null ? true : false;
  var url = "/me/messages?access_token=" + token;

  var jsondata = {
    recipient: { id: userID },
    messaging_type: "RESPONSE",
    message: {
      text: text,
      quick_replies: [
        {
          content_type: "user_email",
        },
      ],
    },
  };

  if (IsThan24h) {
    jsondata.messaging_type = "MESSAGE_TAG";
    jsondata.tag = tagChat;
  }

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(jsondata),
  };
  return fetch(fbUrl + url, requestOptions).then(handleResponse, handleError);
};

export const sendShipInfo = (userID, token, pageID, tagChat, text, data) => {
  var IsThan24h = tagChat != null ? true : false;
  var url = "/me/messages?access_token=" + token;

  var jsondata = {
    recipient: { id: userID },
    message: {
      attachment: {
        type: "template",
        payload: {
          template_type: "button",
          text: text,
          buttons: [
            {
              type: "web_url",
              url: `https://donhang.io/donhang.html?shopID=${data.ShopID}&orderID=${data.TrackingID}`,
              title: "Tra cứu",
              messenger_extensions: "TRUE",
            },
          ],
        },
      },
    },
  };

  jsondata.messaging_type = "MESSAGE_TAG";
  jsondata.tag = "POST_PURCHASE_UPDATE";

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(jsondata),
  };
  return fetch(fbUrl + url, requestOptions).then(handleResponse, handleError);
};


export const getPostDetail = (postid, token) => {
  var url = "/" + postid + "?fields=created_time,message,story,id,attachments&access_token=" + token;
  var params = {
    method: "GET",
  };
  return fetch(fbUrl + url, params).then(handleResponse, handleError);
};

export const GetListFeed = (token, url) => {
  if (url == null) {
    url = "/me/feed?fields=message,story,created_time,id,attachments,child_attachments&access_token=" + token;
  }
  const params = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(fbUrl + url, params).then(handleResponse, handleError);
};

export const GetPostDetail = (postid, token) => {
  var url = "/" + postid + "?fields=created_time,message,story,id,attachments&access_token=" + token;
  const params = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  return fetch(fbUrl + url, params).then(handleResponse, handleError);
};
