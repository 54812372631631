import { Skeleton, Row, Col, Button, Image, Modal } from "antd";
import React from "react";
import api from "api";
import { formatLongDate, formatCurrency } from "common/Format2";
import { imageURL } from "config";
import { useNavigate, useParams } from "react-router-dom";

const PopupOrderInfo = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [orderID, setOrderID] = React.useState();
  const [data, setData] = React.useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  React.useImperativeHandle(ref, () => ({
    open(id) {
      setVisible(true);
      setOrderID(id);
      getData(id);
    },
  }));

  const getData = (id) => {
    api.get("OrderOnline/GetByID?id=" + id).then((res) => {
      res.data.images = res.data.images ? JSON.parse(res.data.images) : [];
      setData(res.data);
      setLoading(false);
    });
  };

  const close = () => {
    setVisible(false);
  };

  return (
    <div>
      <Modal
        maskClosable={false}
        width={600}
        open={visible}
        title={"Thông tin đơn đặt hàng"}
        onCancel={() => {
          close();
        }}
        footer={
          id
            ? []
            : [
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    close();
                    navigate("/save_order_online/" + orderID);
                  }}
                >
                  Sửa
                </Button>,
              ]
        }
      >
        {loading ? (
          <Skeleton />
        ) : (
          <div>
            <div style={{ marginBottom: 20 }}>
              <div style={{ fontWeight: "bold" }}>Đơn hàng được tạo vào lúc &nbsp; {formatLongDate(data.create_date)}</div>
              <div style={{ fontWeight: "bold" }}>
                Trạng thái đơn hàng: &nbsp;<span style={{ color: "red" }}>{data.is_delivery ? "ĐÃ GIAO HÀNG" : "CHƯA GIAO HÀNG"}</span>
              </div>
            </div>
            <div>
              <Row gutter={20}>
                <Col span={10}>Tên KH:</Col>
                <Col span={14} style={{ fontWeight: "bold" }}>
                  {data.customer_name}
                </Col>
                <Col span={10}>Nền tảng:</Col>
                <Col span={14} style={{ fontWeight: "bold" }}>
                  {data.source.replace(/\b\w+/g, function (word) {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                  })}
                </Col>
                <Col span={10}>SDT:</Col>
                <Col span={14} style={{ fontWeight: "bold" }}>
                  {data.customer_phone}
                </Col>
                <Col span={10}>Địa chỉ:</Col>
                <Col span={14} style={{ fontWeight: "bold" }}>
                  {data.customer_address}
                </Col>
                <Col span={10}>Thợ đang gửi gia công:</Col>
                <Col span={14} style={{ fontWeight: "bold" }}>
                  {data.worker_name}
                </Col>
                <Col span={10}>Giá:</Col>
                <Col span={14} style={{ fontWeight: "bold" }}>
                  {formatCurrency(data.price)}
                </Col>
                <Col span={10}>Cọc:</Col>
                <Col span={14} style={{ fontWeight: "bold" }}>
                  {formatCurrency(data.deposit)}
                </Col>
                <Col span={10}>Số tiền còn lại:</Col>
                <Col span={14} style={{ fontWeight: "bold" }}>
                  {formatCurrency(data.price - data.deposit)}
                </Col>
              </Row>
              <div>Ghi chú:</div>
              <div style={{ marginLeft: 30, fontWeight: "bold" }}>{data.notes}</div>
              <div>Hình ảnh sản phẩm:</div>
              <div style={{ display: "flex", gap: 10, overflowX: "scroll" }}>
                {data.images.map((x) => {
                  return <Image style={{ width: 100, height: 100 }} src={imageURL + x.image} preview={true}></Image>;
                })}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
});
export default PopupOrderInfo;
