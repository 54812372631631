import React from "react";
import { Button, Input, Radio, Checkbox, Card, Tabs, InputNumber, Select, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import api from "api";
import { createSearchText } from "common/Text";
import { formatCurrency } from "common/Format2";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { Print } from "component/print";
import Bill from "component/print/bill";
import PopupInsert from "component/home/popup_insert";
import PopupShowPayTransaction from "component/home/popup_show_pay_transaction";
import { Header } from "Client";

const CustomerTransaction = (props) => {
  const [tabKey, setTabKey] = React.useState("purchase");
  const [lstFabric, setLstFabric] = React.useState([]);
  const [payData, setPayData] = React.useState({ type: "pay", payment_method: "cash", is_deposit: false });
  const [data, setData] = React.useState({ goods_type: "clothes" });
  const [lstPurchase, setLstPurchase] = React.useState([]);
  const [lstReturn, setLstReturn] = React.useState([]);
  const [lstID, setLstID] = React.useState([]);
  const [mode, setMode] = React.useState("transaction");
  const [lastTransaction, setLastTransaction] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const popupInsert = React.useRef();
  const popupShowPayTransaction = React.useRef();
  const { userLogin, header, setShowHeader } = React.useContext(Header);
  const [customer, setCustomer] = React.useState({});

  React.useEffect(() => {
    getListFabric();
    getCustomer();
  }, []);

  const getListFabric = () => {
    api.get("/Fabric/GetList").then((res) => {
      setLstFabric(res.data);
    });
  };

  const getCustomer = () => {
    api.get("/Customer/GetByID?id=" + id).then((res) => {
      setCustomer(res.data);
    });
  };

  const items = [
    {
      key: "purchase",
      label: "Lấy hàng",
    },
    {
      key: "return",
      label: "Trả hàng",
    },
  ];

  const tabChange = (key) => {
    setTabKey(key);
    setData({ goods_type: "clothes" });
  };

  const payChange = (value, name) => {
    var pay = { ...payData };
    pay[name] = value;
    setPayData(pay);
  };

  const dataChange = (value, name) => {
    var tran = { ...data };
    if (name == "fabric_id") {
      tran.fabric_name = value.name;
      tran.fabric_id = value.id;
      api.get(`Customer/GetLastFabricTransaction?customerID=${id}&fabricID=${value.id}`).then((res) => {
        if (res.data) {
          tran.price = Math.abs(res.data.price);
        } else {
          if (value.price) tran.price = Math.abs(value.price);
          else tran.price = null;
        }
        setData(tran);
      });
    } else {
      tran[name] = value;
      setData(tran);
    }
  };

  const addTransaction = () => {
    data.type = tabKey;
    if (data.goods_type == "clothes" && (!data.product_name || data.product_name == "")) {
      message.error("Vui lòng nhập tên sản phẩm");
      return;
    }
    if (data.goods_type == "fabric" && !data.fabric_id) {
      message.error("Vui lòng chọn loại vải");
      return;
    }
    if (data.goods_type == "fabric" && !data.fabric_height) {
      message.error("Vui lòng nhập mét vải");
      return;
    }
    if (!data.quantity) {
      message.error("Vui lòng nhập số lượng");
      return;
    }

    if (!data.price) {
      message.error("Vui lòng nhập giá");
      return;
    }
    if (data.goods_type == "clothes") data.fabric_height = null;
    if (tabKey == "purchase") {
      var lst = [...lstPurchase];
      lst.push({ ...data });
      setLstPurchase(lst);
    }
    if (tabKey == "return") {
      var lst = [...lstReturn];
      lst.push({ ...data });
      setLstReturn(lst);
    }
  };

  const beforePrint = () => {
    setMode("print");
    setShowHeader(false);
  };
  const afterPrint = () => {
    setMode("bill");
    setShowHeader(true);
  };

  const removeData = (index) => {
    var lst = [...lstPurchase];
    if (tabKey == "return") lst = [...lstReturn];
    lst.splice(index, 1);
    if (tabKey == "return") setLstReturn(lst);
    if (tabKey == "purchase") setLstPurchase(lst);
  };

  const saveTransaction = () => {
    setLoading(true);
    var lstTransaction = [...lstPurchase, ...lstReturn];
    if (payData.price) {
      lstTransaction.push(payData);
    }
    api
      .get("Customer/GetLastTransaction?customerID=" + id)
      .then((res) => {
        setLastTransaction(res.data);
        api
          .post("Customer/AddTranssactions", { transactions: lstTransaction, customerID: id })
          .then((res) => {
            setLoading(false);
            setLstID(res.data);
            setMode("bill");
          })
          .catch((err) => {
            setLoading(false);
            message.error(err.message);
          });
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  };

  return (
    <div>
      {mode == "transaction" ? (
        <div>
          <Card style={{ marginBottom: 20 }}>
            <div style={{ display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", gap: 20 }}>
              <Button
                style={{ flex: 1, marginBottom: 10 }}
                size="large"
                type="primary"
                onClick={() => {
                  navigate(`/customer_history/${id}`);
                }}
              >
                Xem sổ
              </Button>

              <Button
                size="large"
                type="primary"
                style={{ flex: 1, marginBottom: 10 }}
                onClick={() => {
                  navigate("/customer_info/" + id);
                }}
              >
                Thông tin KH
              </Button>
              <Button
                style={{ flex: 1 }}
                size="large"
                type="primary"
                onClick={() => {
                  popupInsert.current.open("Fabric");
                }}
              >
                Thêm loại vải
              </Button>
              {userLogin.role_id == 1 ? (
                <Button
                  style={{ flex: 1 }}
                  size="large"
                  type="primary"
                  onClick={() => {
                    popupShowPayTransaction.current.open("Customer", id);
                  }}
                >
                  Liệt kê
                </Button>
              ) : (
                <></>
              )}
            </div>
          </Card>
          <Card style={{ marginBottom: 20 }} title="Giao dịch">
            <div>
              <div style={{ marginBottom: 20 }}>
                <InputNumber
                  inputMode="numeric"
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  step={1000}
                  size="large"
                  placeholder="Số tiền"
                  style={{ width: "100%" }}
                  value={payData.price}
                  onChange={(e) => {
                    payChange(e, "price");
                  }}
                ></InputNumber>
              </div>
              {customer.is_wholesale ? (
                <div style={{ marginBottom: 20 }}>
                  <Checkbox
                    checked={payData.is_deposit}
                    size="large"
                    onChange={(e) => {
                      payChange(e.target.checked, "is_deposit");
                    }}
                  >
                    Tiền cọc
                  </Checkbox>
                </div>
              ) : (
                <></>
              )}

              <div style={{ marginBottom: 20 }}>
                <Radio.Group
                  name="radiogroup"
                  value={payData.payment_method}
                  size="large"
                  onChange={(e) => {
                    payChange(e.target.value, "payment_method");
                  }}
                >
                  <Radio value={"transfer"}>Chuyển khoản</Radio>
                  <Radio value={"cash"}>Tiền mặt</Radio>
                </Radio.Group>
              </div>
            </div>
            <Tabs items={items} onChange={tabChange} type="card" centered />

            <div>
              <div style={{ marginBottom: 20 }}>
                <Radio.Group
                  size="large"
                  name="radiogroup"
                  value={data.goods_type}
                  onChange={(e) => {
                    dataChange(e.target.value, "goods_type");
                  }}
                >
                  <Radio value={"clothes"}>{tabKey == "purchase" ? "Mua áo" : "Trả áo"}</Radio>
                  <Radio value={"fabric"}>{tabKey == "purchase" ? "Mua vải" : "Trả vải"}</Radio>
                </Radio.Group>
              </div>
              <div style={{ marginBottom: 20 }}>
                {data.goods_type == "clothes" ? (
                  <Input
                    placeholder="Tên sản phẩm"
                    size="large"
                    value={data.product_name}
                    onChange={(e) => {
                      dataChange(e.target.value, "product_name");
                    }}
                  ></Input>
                ) : (
                  <Select
                    placeholder="Chọn loại vải"
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    options={lstFabric}
                    fieldNames={{ label: "name", value: "id" }}
                    showSearch={true}
                    autoClearSearchValue={true}
                    filterOption={(key, option) => {
                      key = createSearchText(key);
                      var label = createSearchText(option.name);
                      if (label.includes(key)) return true;
                      return false;
                    }}
                    virtual={false}
                    value={data.fabric_id}
                    onChange={(e, f) => {
                      dataChange(f, "fabric_id");
                    }}
                  />
                )}
              </div>
              <div style={{ marginBottom: 20 }}>
                <InputNumber
                  inputMode="numeric"
                  min={0}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  size="large"
                  placeholder="Số lượng"
                  style={{ marginRight: 10, marginBottom: 10 }}
                  value={data.quantity}
                  onChange={(e) => {
                    dataChange(e, "quantity");
                  }}
                ></InputNumber>
                {data.goods_type == "fabric" ? (
                  <InputNumber
                    pattern="[0-9]*[.]?[0-9]*"
                    inputMode="decimal"
                    min={0}
                    size="large"
                    placeholder="Mét vải"
                    style={{ marginRight: 10, marginBottom: 10 }}
                    value={data.fabric_height}
                    onChange={(e) => {
                      dataChange(e, "fabric_height");
                    }}
                  ></InputNumber>
                ) : (
                  <></>
                )}
                <InputNumber
                  inputMode="numeric"
                  min={0}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  step={1000}
                  size="large"
                  placeholder="Giá"
                  style={{ width: 150 }}
                  value={data.price}
                  onChange={(e) => {
                    dataChange(e, "price");
                  }}
                ></InputNumber>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button size="large" type="primary" onClick={addTransaction}>
                  Thêm
                </Button>
              </div>
              <hr />
            </div>

            {tabKey == "purchase" && lstPurchase.length != 0 ? (
              <div>
                {lstPurchase.map((x, index) => {
                  return (
                    <div style={{ marginBottom: 10 }}>
                      <div style={{ fontWeight: "bold" }}>{x.goods_type == "clothes" ? x.product_name : x.fabric_name}</div>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                          {x.goods_type == "clothes" ? (
                            x.quantity
                          ) : (
                            <>
                              {`${x.quantity}`}&nbsp;&nbsp; <CloseOutlined />
                              &nbsp;&nbsp;{`${x.fabric_height}m`}
                            </>
                          )}
                        </div>
                        <div>
                          <CloseOutlined />
                        </div>
                        <div>{formatCurrency(x.price)}đ</div>
                        <div>=</div>
                        <div>{formatCurrency(x.quantity * x.price * (x.fabric_height ?? 1))}đ</div>
                        <div>
                          <DeleteOutlined onClick={() => removeData(index)} />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  Tổng cộng: &nbsp;
                  {formatCurrency(
                    lstPurchase.reduce((tong, x) => {
                      return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                    }, 0)
                  )}
                  đ
                </div>
              </div>
            ) : (
              <></>
            )}
            {tabKey == "return" && lstReturn.length != 0 ? (
              <div>
                {lstReturn.map((x, index) => {
                  return (
                    <div style={{ marginBottom: 10 }}>
                      <div style={{ fontWeight: "bold" }}>{x.goods_type == "clothes" ? x.product_name : x.fabric_name}</div>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                          {x.goods_type == "clothes" ? (
                            x.quantity
                          ) : (
                            <>
                              {`${x.quantity}`}&nbsp;&nbsp;
                              <CloseOutlined />
                              &nbsp;&nbsp; {`${x.fabric_height}m`}
                            </>
                          )}
                        </div>
                        <div>
                          <CloseOutlined />
                        </div>
                        <div>{formatCurrency(x.price)}đ</div>
                        <div>=</div>
                        <div>{formatCurrency(x.quantity * x.price * (x.fabric_height ?? 1))}đ</div>
                        <div>
                          <DeleteOutlined onClick={() => removeData(index)} />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  Tổng cộng: &nbsp;
                  {formatCurrency(
                    lstReturn.reduce((tong, x) => {
                      return tong + x.price * x.quantity * (x.fabric_height ?? 1);
                    }, 0)
                  )}
                  đ
                </div>
              </div>
            ) : (
              <></>
            )}
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
              <Button
                style={{ width: "47%" }}
                size="large"
                type="primary"
                onClick={() => {
                  saveTransaction();
                }}
                loading={loading}
              >
                Submit
              </Button>
              <Button
                type="primary"
                size="large"
                style={{ width: "47%" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Trở lại
              </Button>
            </div>
          </Card>
        </div>
      ) : (
        <>
          {mode == "bill" ? (
            <>
              <div style={{ marginBottom: 20 }}>
                <Bill lstPurchase={lstPurchase} lstReturn={lstReturn} payData={payData} lastTransaction={lastTransaction} type="web" customer={customer} />
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Print
                  type="bill"
                  lastTransaction={lastTransaction}
                  payData={payData}
                  lstPurchase={lstPurchase}
                  lstReturn={lstReturn}
                  customer={customer}
                  style={{ width: "47%" }}
                  header={header}
                  afterPrint={afterPrint}
                  beforePrint={beforePrint}
                />
                <Button
                  type="primary"
                  size="large"
                  style={{ width: "47%" }}
                  onClick={() => {
                    setData({ goods_type: "clothes" });
                    setPayData({ type: "pay", payment_method: "cash" });
                    setLstPurchase([]);
                    setLstReturn([]);
                    setTabKey("purchase");
                    setMode("transaction");
                  }}
                >
                  Trở lại
                </Button>
              </div>
            </>
          ) : (
            <div>
              <Bill lstPurchase={lstPurchase} lstReturn={lstReturn} payData={payData} lastTransaction={lastTransaction} header={header} customer={customer} />
            </div>
          )}
        </>
      )}
      <PopupInsert ref={popupInsert} getListFabric={getListFabric} />
      <PopupShowPayTransaction ref={popupShowPayTransaction} />
    </div>
  );
};
export default CustomerTransaction;
