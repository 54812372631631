import React, { useState } from "react";
import { Modal, Input, Button, message } from "antd";
import api from "api";
import { CheckOutlined } from "@ant-design/icons";
const AddTagModal = ({ visible, onCancel, onApply }) => {
  const [tagName, setTagName] = useState("");
  const [selectedColor, setSelectedColor] = useState(null);

  const colors = [
    "ff7a74",
    "ff0000",
    "ffbf47",
    "ff8c00",
    "ffd700",
    "ffff00",
    "00ff00",
    "228b22",
    "40e0d0",
    "00ced1",
    "0000ff",
    "00008b",
    "6a5acd",
    "8a2be2",
    "9400d3",
    "ff00ff",
    "ff1493",
    "c71585",
    "696969",
    "000000",
  ];

  const handleApply = () => {
    if (tagName && selectedColor) {
      api.post("/Facebook/AddNewTag?name=" + tagName + "&color=" + selectedColor).then((res) => {
        onApply();
        setTagName("");
        setSelectedColor(null);
      })
      .catch((err) => {
        message.error(err.message);
      });
    }
  };

  return (
    <Modal
      title="Thêm thẻ hội thoại"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Hủy bỏ
        </Button>,
        <Button key="apply" type="primary" onClick={handleApply} disabled={!tagName || !selectedColor}>
          Áp dụng
        </Button>,
      ]}
    >
      <div>
        <label>
          Tên Thẻ <span style={{ color: "red" }}>*</span>
        </label>
        <Input placeholder="Nhập tên thẻ" value={tagName} onChange={(e) => setTagName(e.target.value)} style={{ marginBottom: "16px" }} />
      </div>

      <div>
        <label>Chọn màu</label>
        <div style={{ display: "flex", flexWrap: "wrap", marginTop: "8px" }}>
          {colors.map((color) => (
            <div
              key={color}
              onClick={() => setSelectedColor(color)}
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                backgroundColor: "#" + color,
                margin: 7,
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {selectedColor === color && <CheckOutlined style={{ color: "white", fontSize: 18 }} />}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AddTagModal;
