import { Button, Form, Input, InputNumber, TimePicker, message, Modal, Row, Col } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";
import { Print } from "component/print";
import dayjs from "dayjs";
import BillLive from "component/print/bill_live";
import api from "api";
import { useNavigate } from "react-router-dom";
import { Header } from "../../Client";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const { TextArea } = Input;

const Live = () => {
  const [data, setData] = React.useState({});
  const form = React.useRef();
  const printRef = React.useRef();
  React.useEffect(() => {}, []);

  const onFinish = (values) => {
    values.price = values.price * 1000;
    setData(values);
    setTimeout(() => {
      printRef.current.print();
    }, 500);
  };

  return (
    <div>
      <div style={{ marginRight: 20, marginLeft: 20 }}>
        <Form ref={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Form.Item
                label="Tên"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label="Mã số"
                name="product_id"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item label="Giá" name="price">
                <InputNumber
                  style={{ width: " 100%" }}
                  inputMode="numeric"
                  min={0}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  step={1000}
                  size="large"
                />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item label="Ghi chú" name="notes">
                <TextArea rows={5} />
              </Form.Item>
            </Col> */}
          </Row>

          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Print
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
      <div style={{ display: "none" }}>
        <Print type="bill_live2" data={data} ref={printRef} />
      </div>
    </div>
  );
};
export default Live;
