import React, { useState, useEffect, useRef } from "react";
import CommentItem from "./comment_item";
import InfiniteScroll from "react-infinite-scroller";
import { Spin, Button, Empty, notification } from "antd";
import moment from "moment";
import api from "api";

const ListComment = (props) => {
  const [search, setSearch] = useState("");
  const scrollRef = useRef(null);
  const scrollTop = useRef(0);
  const [availableTag, setAvailableTag] = useState([]);


  const handleLoadMore = () => {
    props.loadMoreComment();
  };

  useEffect(() => {
    getCurrentTag();
  }, []);
  const getCurrentTag = () => {
    api.get("Facebook/GetCurrentTag").then((res) => {
      setAvailableTag(res.data);
    });
  };

  const getSortList = () => {
    return [...props.lstComment].sort((a, b) =>
      moment(a.lastupdatetime).isAfter(b.lastupdatetime) ? -1 : 1
    );
  };


  const lstItem = getSortList().map((item) => (
    <CommentItem
      {...props}
      data={item}
      selectedComment={props.selectedComment}
      key={"list_comment_" + item.id}
      onClick={props.onClick}
      showCheckbox={props.showCheckbox}
      checked={props.checkedItems.includes(item.id)} 
      onCheckboxChange={props.onshowMulChat}
      lsttag={availableTag}
    />
  ));

  let scrollHeight = "calc(100vh - 50px)";

  return (
    <div>
      <div className="conversation-list">
        <div
          ref={scrollRef}
          className="infinite-conv-list"
          style={{ height: scrollHeight, overflow: "hidden scroll" }}
        >
          <InfiniteScroll
            pageStart={1}
            loadMore={handleLoadMore}
            initialLoad={false}
            hasMore={!props.isEnd}
            useWindow={false}
          >
            {props.isSearching && (
              <div style={{ margin: "5px auto", width: 40 }}>
                <Spin />
              </div>
            )}
            {!props.isSearching && props.lstComment.length === 0 && (
              <Empty
                style={{ height: "50px" }}
                description="Load data"
              />
            )}
            {lstItem}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default ListComment;
