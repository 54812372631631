import React from "react";
import { Image, Space, Upload, message, Button, Card } from "antd";
import ImgCrop from "antd-img-crop";
import api from "api";
import { imageURL } from "config/index";

export default class UploadImagePicker extends React.Component {
  state = { isUploading: false, data: [] };

  constructor(props) {
    super(props);
  }
  onChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  uploadImage = (e) => {
    var form = new FormData();
    if (e.file.size > 3145728) message.error("Vui lòng upload file hình ảnh dưới 3MB");

    if (this.fileFilter(e.file)) {
      form.append("file", e.file);
      form.append("type", "image");
      this.setState({ isUploading: true });
      api
        .post("Upload/Upload", form)
        .then((res) => {
          this.setState({ isUploading: false });
          this.setState((prevState) => {
            prevState.data.push(res.data[0]);
            return {
              data: [...prevState.data],
            };
          });
          setTimeout(() => {
            this.onChange(this.state.data);
          }, 500);
        })
        .catch((err) => {
          message.error("Không thể tải file. Vui lòng thử lại");
          this.setState({ isUploading: false });
          console.log(err);
        });
    } else message.error("Vui lòng upload file hình ảnh đuôi png, jpg, jpeg, svg hoặc gif");
  };

  fileFilter = (file) => {
    var ext = "";
    var fileName = file.name;
    var i = fileName.lastIndexOf(".");
    if (i === -1) ext = "unknown";
    else ext = fileName.slice(i + 1);
    var lstAllow = [
      //image
      "jpg",
      "jpeg",
      "png",
      "gif",
      "svg",
    ];

    ext = ext.toLocaleLowerCase();
    // Accept images only
    if (!lstAllow.includes(ext)) {
      return false;
    }
    return true;
  };

  isValidSrc = (src) => {
    if (src && src != "" && typeof src == "string") {
      return true;
    }
    return false;
  };

  beforeDrop = (e) => {
    var lst = e.name.split(".");
    var tail = lst[lst.length - 1];
    var lstAllow = [
      //image
      "jpg",
      "jpeg",
      "png",
      "gif",
      "svg",
    ];
    tail = tail.toLocaleLowerCase();
    // Accept images only
    if (!lstAllow.includes(tail)) {
      message.error("Vui lòng upload file hình ảnh đuôi png, jpg, jpe, svg hoặc gif");
      return false;
    }
    return true;
  };
  beforeUpload = (file) => {
    //Tiến hành resize file ảnh phù hợp với kích cở
    return new Promise((resolve) => {
      if (this.props.resize) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = document.createElement("img");
          img.src = reader.result;

          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = this.props.resize.width;
            canvas.height = this.props.resize.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, this.props.resize.width, this.props.resize.height);
            canvas.toBlob((result) => resolve(result));
          };
        };
      } else {
        resolve(file);
      }
    });
  };

  render() {
    var img_height = "auto";
    var img_width = "100%";
    if (this.props.size) {
      img_width = this.props.size.width || "100%";
      img_height = this.props.size.height || "100%";
    }

    return (
      <div>
        <div>
          <div style={{ display: "flex", gap: 10, overflowX: "scroll" }}>
            {this.state.data.length != 0 ? (
              <>
                {this.state.data.map((x) => {
                  return <Image style={{ width: 100, height: 100 }} src={imageURL + x.image} preview={true}></Image>;
                })}
              </>
            ) : (
              <Card width="64" height="64">
                <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>No image</div>
              </Card>
            )}
          </div>
        </div>

        <div style={{ display: "flex", textAlign: "center", justifyContent: "center", marginTop: 10 }}>
          <div>
            {!this.props.disabled && (
              <Space>
                {this.props.crop ? (
                  <>
                    <ImgCrop {...this.props.crop} minZoom={0.1} cropperProps={{ restrictPosition: false }} beforeCrop={this.beforeDrop}>
                      <Upload accept=".png,.jpg,.jpeg,.gif" showUploadList={false} customRequest={this.uploadImage} beforeUpload={this.beforeUpload}>
                        <Button type="link" loading={this.state.isUploading}>
                          {this.state.isUploading ? "Uploading" : "Upload"}
                        </Button>
                      </Upload>
                    </ImgCrop>
                  </>
                ) : (
                  <Upload
                    accept=".png,.jpg,.jpeg,.gif"
                    showUploadList={false}
                    customRequest={this.uploadImage}
                    beforeUpload={this.beforeUpload}
                    multiple={true}
                  >
                    <Button type="link" loading={this.state.isUploading}>
                      {this.state.isUploading ? "Uploading" : "Upload"}
                    </Button>
                  </Upload>
                )}

                <Button
                  style={{ display: this.props.showClear ? "block" : "none" }}
                  onClick={(e) => {
                    this.onChange(null);
                  }}
                  type="link"
                >
                  Clear
                </Button>
              </Space>
            )}
          </div>
        </div>
      </div>
    );
  }
}
