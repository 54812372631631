import React from "react";
import { formatCurrency, formatShortDate } from "common/Format2";
import { EditOutlined, RestOutlined, CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Table, Button, Card, Popconfirm, Space, message } from "antd";
import { formatLongDate } from "common/Format2";
import PopupEditPay from "component/home/popup_edit_pay";
import PopupEditPurchase from "component/home/popup_edit_purchase";
import api from "api";
import { Header } from "Client";

const History = React.forwardRef((props, ref) => {
  const [loading, setLoading] = React.useState();
  const popupPay = React.useRef();
  const popupPurchase = React.useRef();
  const { userLogin, header } = React.useContext(Header);

  const colsWeb = [
    {
      title: "Thời gian",
      dataIndex: "create_date",
      render: (text, record, index) => {
        return dayjs(record.create_date).format("HH:mm");
      },
    },
    {
      title: "Tên hàng",
      dataIndex: "type",
      render: (text, record, index) => {
        switch (text) {
          case "return":
            if (record.goods_type == "clothes") return record.product_name;
            else return record.fabric_name;
          case "pay":
            return "Thanh toán";
          case "purchase":
            if (record.goods_type == "clothes") return record.product_name;
            else return record.fabric_name;

          default:
            return;
        }
      },
    },

    {
      title: "SL",
      dataIndex: "quantity",
      render: (text, record, index) => {
        if (record.goods_type == "clothes") return record.quantity;
        return (
          <div>
            {record.quantity} &nbsp;&nbsp;
            <CloseOutlined />
            &nbsp;&nbsp;{record.fabric_height}m
          </div>
        );
      },
    },
    {
      title: "Giá",
      dataIndex: "price",
      render: (text, record, index) => {
        if (record.price >= 0) return <div>{formatCurrency(Math.abs(record.price))}</div>;
        else return <div style={{ color: "red" }}>{formatCurrency(Math.abs(record.price))}</div>;
      },
    },
    {
      title: "Tổng",
      dataIndex: "total",
      render: (text, record, index) => {
        if (record.total >= 0) return <div>{formatCurrency(record.total)}</div>;
        else return <div style={{ color: "red" }}>{formatCurrency(record.total)}</div>;
      },
    },
    {
      render: (text, record, index) => {
        return (
          <Space>
            {userLogin.role_id != 3 ? (
              <Button
                loading={loading}
                type="primary"
                onClick={() => {
                  popupPurchase.current.open(props.mode, record);
                }}
              >
                <EditOutlined />
              </Button>
            ) : (
              <></>
            )}

            <Popconfirm
              loading={loading}
              title="Xóa sổ"
              description="Bạn có muôn xóa dòng này không?"
              onConfirm={() => {
                const name = props.mode.toLowerCase() + "_id";

                deleteTransaction(record.id, record[name]);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={loading}>
                <RestOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const cols = [
    {
      title: "Thời gian",
      dataIndex: "create_date",
      render: (text, record, index) => {
        return dayjs(record.create_date).format("HH:mm");
      },
    },
    {
      title: "Tên hàng",
      dataIndex: "type",
      render: (text, record, index) => {
        switch (text) {
          case "return":
            if (record.goods_type == "clothes") return record.product_name;
            else return record.fabric_name;
          case "pay":
            return "Thanh toán";
          case "purchase":
            if (record.goods_type == "clothes") return record.product_name;
            else return record.fabric_name;

          default:
            return;
        }
      },
    },

    {
      title: "SL",
      dataIndex: "quantity",
      render: (text, record, index) => {
        if (record.goods_type == "clothes") return record.quantity;
        return (
          <div>
            {record.quantity} &nbsp;&nbsp;
            <CloseOutlined />
            &nbsp;&nbsp;{record.fabric_height}m
          </div>
        );
      },
    },
    {
      title: "Giá",
      dataIndex: "price",
      render: (text, record, index) => {
        if (record.price >= 0) return <div>{formatCurrency(Math.abs(record.price))}</div>;
        else return <div style={{ color: "red" }}>{formatCurrency(Math.abs(record.price))}</div>;
      },
    },
    {
      title: "Tổng",
      dataIndex: "total",
      render: (text, record, index) => {
        if (record.total >= 0) return <div>{formatCurrency(record.total)}</div>;
        else return <div style={{ color: "red" }}>{formatCurrency(record.total)}</div>;
      },
    },
  ];

  const col2sWeb = [
    {
      title: "Thời gian",
      dataIndex: "create_date",
      render: (text, record, index) => {
        return dayjs(record.create_date).format("HH:mm");
      },
    },

    {
      title: "PTTT",
      dataIndex: "payment_method",
      render: (text, record, index) => {
        var data = "";
        if (text == "cash") data = "Tiền mặt";
        else data = "Chuyển khoản";
        if (record.is_deposit) data += " (cọc)";
        return data;
      },
    },
    {
      title: "Giá",
      dataIndex: "price",
      render: (text, record, index) => {
        if (record.price >= 0) return <div>{formatCurrency(record.price)}</div>;
        else return <div style={{ color: "red" }}>{formatCurrency(record.price)}</div>;
      },
    },
    {
      render: (text, record, index) => {
        return (
          <Space>
            {userLogin.role_id != 3 ? (
              <Button
                loading={loading}
                type="primary"
                onClick={() => {
                  popupPay.current.open(props.mode, record);
                }}
              >
                <EditOutlined />
              </Button>
            ) : (
              <></>
            )}
            <Popconfirm
              loading={loading}
              title="Xóa sổ"
              description="Bạn có muôn xóa dòng này không?"
              onConfirm={() => {
                const name = props.mode.toLowerCase() + "_id";
                deleteTransaction(record.id, record[name]);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={loading}>
                <RestOutlined />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const col2s = [
    {
      title: "Thời gian",
      dataIndex: "create_date",
      render: (text, record, index) => {
        return dayjs(record.create_date).format("HH:mm");
      },
    },

    {
      title: "PTTT",
      dataIndex: "payment_method",
      render: (text, record, index) => {
        var data = "";
        if (text == "cash") data = "Tiền mặt";
        else data = "Chuyển khoản";
        if (record.is_deposit) data += " (cọc)";
        return data;
      },
    },
    {
      title: "Giá",
      dataIndex: "price",
      render: (text, record, index) => {
        if (record.price >= 0) return <div>{formatCurrency(record.price)}</div>;
        else return <div style={{ color: "red" }}>{formatCurrency(record.price)}</div>;
      },
    },
  ];
  const deleteTransaction = (id, customerID) => {
    setLoading(true);
    api
      .get(`${props.mode}/DeleteTransaction?${props.mode.toLowerCase()}ID=${customerID}&id=${id}`)
      .then((res) => {
        setLoading(false);
        message.success("Xóa thành công");
        props.getData();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Xóa thất bại");
      });
  };

  return (
    <div ref={ref} style={!props.type ? { padding: 20 } : {}}>
      {props.type != "web" ? (
        <div
          style={{
            fontWeight: "bold",
            fontSize: 20,
            textAlign: "center",
            padding: 20,
            backgroundColor: "#fff",
          }}
        >
          {props.header}
        </div>
      ) : (
        <></>
      )}
      <div>
        {props.listData.map((x) => {
          if (x.lst.length > 0) {
            var debt = x.lst[x.lst.length - 1]?.profit ?? 0;
            var debtOld = (x.lst[0]?.profit ?? 0) - (x.lst[0]?.total ?? 0);
            var lstPurchase = x.lst.filter((x) => x.type == "purchase");
            var lstReturn = x.lst.filter((x) => x.type == "return");
            var lstPay = x.lst.filter((x) => x.type == "pay");
            var totalPurchase = lstPurchase.reduce((tong, item) => {
              return tong + item.total;
            }, 0);
            var totalReturn = lstReturn.reduce((tong, item) => {
              return tong + item.total;
            }, 0);
            var totalPay = lstPay.reduce((tong, item) => {
              return tong + item.total;
            }, 0);
            return (
              <div style={{ marginBottom: 20 }}>
                <div style={{ fontWeight: "bold", marginBottom: 10, color: "red", fontSize: 20 }}>{dayjs(x.date).format("DD-MM-YYYY")}</div>
                <div>
                  {lstPurchase.length > 0 ? (
                    <div>
                      <div style={{ fontWeight: "bold", textAlign: "center", marginBottom: 10 }}>Lấy hàng</div>
                      <Table
                        columns={props.type != "web" ? cols : colsWeb}
                        dataSource={lstPurchase}
                        bordered
                        pagination={false}
                        footer={() => {
                          return (
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                              <div>Tổng: &nbsp; {formatCurrency(totalPurchase)}</div>
                            </div>
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  {lstReturn.length > 0 ? (
                    <div style={{ marginTop: 10 }}>
                      <div style={{ fontWeight: "bold", textAlign: "center", marginBottom: 10 }}>Trả hàng</div>
                      <Table
                        style={{ color: "red" }}
                        columns={props.type != "web" ? cols : colsWeb}
                        dataSource={lstReturn}
                        bordered
                        pagination={false}
                        footer={() => {
                          return (
                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                              <div>
                                Tổng nợ: &nbsp; <span style={{ color: "red" }}>{formatCurrency(totalReturn)}</span>
                              </div>
                            </div>
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div style={{ marginTop: 10 }}>
                    <div style={{ fontWeight: "bold", textAlign: "center", marginBottom: 10 }}>Thanh toán</div>
                    {lstPay.length > 0 ? (
                      <Table
                        columns={props.type != "web" ? col2s : col2sWeb}
                        dataSource={lstPay}
                        bordered
                        pagination={false}
                        footer={() => {
                          return (
                            <>
                              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <div style={{ fontWeight: "bold" }}>
                                  Nợ cũ: &nbsp; <span style={debtOld < 0 ? { color: "red" } : {}}>{formatCurrency(debtOld)}</span>
                                </div>
                              </div>
                              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                <div style={{ fontWeight: "bold" }}>
                                  Tổng nợ: &nbsp; <span style={debt < 0 ? { color: "red" } : {}}>{formatCurrency(debt)}</span>
                                </div>
                              </div>
                            </>
                          );
                        }}
                      />
                    ) : (
                      <Card>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <div style={{ fontWeight: "bold" }}>
                            Nợ cũ: &nbsp; <span style={debtOld < 0 ? { color: "red" } : {}}>{formatCurrency(debtOld)}</span>
                          </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <div style={{ fontWeight: "bold" }}>
                            Tổng nợ: &nbsp; <span style={debt < 0 ? { color: "red" } : {}}>{formatCurrency(debt)}</span>
                          </div>
                        </div>
                      </Card>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
      <PopupEditPay ref={popupPay} reload={props.getData} />
      <PopupEditPurchase ref={popupPurchase} reload={props.getData} />
    </div>
  );
});
export default History;
