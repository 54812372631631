import React from "react";
import { formatCurrency, formatShortDate } from "common/Format2";
import { Row, Col } from "antd";
import { Header } from "Client";
import dayjs from "dayjs";

const BillLive2 = React.forwardRef((props, ref) => {
  const { time, data } = props;
  const formatTime = (time) => {
    const getSeconds = `0${time % 60}`.slice(-2);
    const minutes = Math.floor(time / 60);
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(time / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  return (
    <div className="bodyPrint" ref={ref}>
      <style>{`@media print {body{zoom: 94%;}}`}</style>
      <div style={{ marginTop: 20 }}>
        <div style={{ fontSize: 12, lineHeight: 1.5 }}>
          <Row gutter={[10, 10]}>
            <Col span={5}>Tên:</Col>
            <Col span={19} style={{ fontWeight: "bold", fontSize: 22 }}>
              {data.name}
            </Col>
            <Col span={5}>Mã số</Col>
            <Col span={19}>{data.product_id}</Col>
            <Col span={5}>Giá:</Col>
            <Col span={19}>{formatCurrency(data.price)}</Col>
            {/* <Col span={5}>Ghi chú:</Col>
            <Col span={19}>{data.notes}</Col> */}
          </Row>
        </div>
      </div>
    </div>
  );
});
export default BillLive2;
